import React, { useState } from "react";
import LogoDoctolib from "../../Images/LogoDoctolib.svg";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { getCentres } from "../../Data/DataCentres";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ApartmentIcon from "@material-ui/icons/Apartment";

const ThemeButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 20,
    //border: theme.border,
    borderRadius: "25px",
    padding: "0px 30px",
    lineHeight: 1.5,

    /*
    backgroundImage: (props) =>
      props.disabled
        ? "green"
        : "linear-gradient(270deg, #107ACA 0%, #107ACA 100%)",
        */
    backgroundColor: "#107ACA",
    fontWeight: 700,
    fontFamily: ["Lato"].join(","),
    "&:hover": {
      //borderColor: "#0062cc",
      boxShadow: "none",
      backgroundColor: "#107ACA",
    },
    "&:active": {
      boxShadow: "none",

      //borderColor: "#005cbf",
    },
    "&:focus": {
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "&:disabled": {
      //backgroundColor: "lightgray",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

export default function BoutonDoctolib({ style, disabled, url, ...props }) {
  //NES Gestion du state
  const [open, setOpen] = useState(false);
  const [centres, setCentres] = useState([]);

  const handleOpen = () => {
    setOpen(true);
    getCentres().then((data) => setCentres(data));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (!url) {
      handleOpen();
    }
  };

  return (
    <div style={{ width: "100%", ...style }}>
      <ThemeButton
        onClick={handleClick}
        style={{
          height: "50px",
          width: "100%",
          color: "white",
          fontWeight: 500,
          fontSize: "18px",
          boxSizing: "border-box",
        }}
        disabled={disabled}
        href={url}
        {...props}
      >
        <img
          src={LogoDoctolib}
          alt="Logo doctolib"
          style={{ marginRight: "20px", height: "20px" }}
        />
        Prendre rdv
      </ThemeButton>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          outline: 0,
        }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            style={{
              backgroundColor: "white",
              margin: "auto",
              padding: "30px",
              maxWidth: "90vw",
              maxHeight: "90vh",
              borderRadius: "10px",
              overflow: "scroll",
            }}
          >
            <header
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="TitrePopup">Sélectionnez votre centre</div>
              <IconButton onClick={handleClose} style={{ marginLeft: "30px" }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </header>
            <div style={{ marginTop: "20px" }}>
              {centres.map((centre, index) => (
                <ListItem
                  key={index}
                  button
                  component="a"
                  divider
                  href={centre.url_doctolib}
                  target="_blank"
                  disabled={centre.url_doctolib ? false : true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ApartmentIcon
                        style={{ color: "#107ACA", marginRight: "10px" }}
                      />
                      <ListItemText
                        primary={centre.nom}
                        secondary={centre.adresse}
                      />
                      <div>{centre.date_ouverture}</div>
                    </div>
                    {centre.actualites && (
                      <div
                        style={{
                          background: "#00579920",
                          padding: "10px",
                          fontSize: "14px",
                          color: "#005799",
                        }}
                      >
                        {centre.actualites}
                      </div>
                    )}
                  </div>
                </ListItem>
              ))}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
