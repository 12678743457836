import React from "react";
import MonStyle from "./Formation.module.css";

export default function Formation({
  heureDebut,
  hauteur,
  imageTop,
  imageBottom,
  color,
  ...props
}) {
  let hauteurCalcule = hauteur * 80 + "px";

  //NES pour le mobile
  if (window.innerWidth < 640) {
    hauteurCalcule = "fit-content";
  }

  return (
    <div className={MonStyle.Container} style={{ height: hauteurCalcule }}>
      <div
        className={MonStyle.Formation}
        style={{ backgroundColor: color + 25, boxShadow: color ? "none" : "" }}
      >
        {imageTop && (
          <img
            className="hide_mobile"
            src={imageTop}
            alt=""
            style={{
              maxWidth: "100%",
              marginBottom: "10px",
              borderRadius: color ? "0px 0px 40px 0px" : "4px",
              borderBottom: "5px solid " + color,
            }}
          />
        )}
        <div className={MonStyle.Nom} style={{ color: color }}>
          {props.children}
        </div>
        <div className={MonStyle.Heure}>{heureDebut}</div>
        {imageBottom && (
          <img
            src={imageBottom}
            alt=""
            className="hide_mobile"
            style={{
              maxWidth: "100%",
              marginTop: "10px",
              borderRadius: color ? "0px 0px 0px 40px" : "4px",
              borderBottom: "5px solid " + color,
            }}
          />
        )}
      </div>
    </div>
  );
}
