import React, { useState, useEffect } from "react";
import MonStyle from "./PageAccompagnement.module.css";
import IllustrationBlois from "../Images/IllustrationBlois.png";
import BoutonContact from "../Design/Boutons/BoutonContact";
import { getCentresFuturs } from "../Data/DataCentres";
import Centre from "../Components/Centre";
import IllustrationArticle1 from "../Images/IllustrationArticle1.jpg";
import IllustrationArticle3 from "../Images/IllustrationArticle3.jpg";
import IllustrationArticle4 from "../Images/IllustrationArticle4.jpg";
import IllustrationArticle5 from "../Images/IllustrationArticle5.jpg";

export default function PageAccompagnement(params) {
  //NES GEstion du state
  const [liste, setListe] = useState([]);

  //USEEFFECT
  useEffect(() => {
    getCentresFuturs().then((data) => {
      console.log(data);
      setListe(data);
    });
  }, []);

  return (
    <div>
      {/* BLOC 1 */}
      <div className={MonStyle.Bloc1}>
        <div className="TitreTurquoise">
          Nous vous accompagnons dans votre installation
        </div>
        <p>
          Pour les nouveaux praticiens dentaires qui viennent s’installer en
          France, ce processus peut être anxiogène et être un frein à ce
          changement de vie. Il s’agit de les intégrer dans la grande famille
          SANTEA.
        </p>
        <p>
          SANTEA a mis au point une démarche d’accompagnement global,
          sécurisante, et facilitant grandement la bonne intégration de ses
          nouveaux collaborateurs :
        </p>
        <ul>
          <li>Aide pour apprentissage de la langue francaise</li>
          <li>Accompagnement pour la recherche d’un logement</li>
          <li>Accompagnement pour toutes les formalités administratives</li>
          <li>
            Accès à des conditions privilégiées aux services de nos différents
            partenaires (bancaires, agences immobilières,….)
          </li>
        </ul>
        <p>
          Des conditions de rémunération attractives et une aide au démarrage
          sont très incitatives. De plus, l’accès à une patientèle importante,
          lié à un choix judicieux de nos centres dentaires, assure un niveau
          d’activité soutenu pour nos dentistes.
        </p>
        <p>
          Cette bienveillance vise à mettre nos nouveaux dentistes dans les
          meilleures conditions de vie personnelles. De facto, cela se traduira
          par un bien-être professionnel, et in fine, par une pratique
          professionnelle sereine et une qualité des soins optimales pour nos
          patients.
        </p>
      </div>

      {/* BLOC 2 */}
      <div className={MonStyle.Bloc2}>
        <div className={MonStyle.ImageEtTitreBloc2}>
          <div className={MonStyle.ContainerImageBloc2}>
            <img
              src={IllustrationBlois}
              alt="ville de blois"
              className={MonStyle.ImageBloc2}
            />
          </div>

          <div className={MonStyle.TitreBloc2}>
            <div className="TitreBlanc">
              Vous souhaitez changer de cadre de vie ?
            </div>
          </div>
        </div>

        <div className={MonStyle.TexteBloc2}>
          <p>
            <mark>Santea</mark> vous propose de vous <mark>accompagner</mark>,
            de vous <mark>intégrer</mark> et de vous
            <mark>soutenir</mark> dans votre <mark>projet de changement</mark>{" "}
            de cadre de vie.
          </p>
          <p>
            Notre <mark>implantation géographique</mark> de notre réseau nous
            permet de vous
            <mark> proposer</mark> une diversité d’opportunités
            professionnelles,
            <mark> correspondant à vos attentes</mark>.
          </p>
          <p>
            Nous vous assurons un
            <mark> accompagnement personnalisé et à la carte</mark>, avec un
            interlocuteur dédié du 1er contact jusqu’à contrat
            <mark> d’embauche</mark> et à <mark>l’intégration</mark> dans l’un
            de nos centres dentaires
          </p>
        </div>
      </div>

      {/* ARTICLES */}
      <div className={MonStyle.BlocArticles}>
        <img
          src={IllustrationArticle1}
          className={MonStyle.IllustrationArticle}
          alt=""
        />
        <div>
          <div className="TitreBleu">AIDE A L’INSCRIPTION A L’ORDRE</div>
          <p>
            Avant l’arrivée au centre, nous proposons aux candidats de venir
            découvrir le centre durant une semaine, au travers d’une observation
            aux côtés de l’équipe médicale. Cela familiarise le futur candidat à
            mieux connaitre l’équipe, les process, les outils et méthodes
            utilisées et se projeter sur son futur cadre de travail. Le retour
            d’expérience des praticiens ayant réalisé cette expérience est très
            positif sur cette approche de découverte du centre.
          </p>
          <p>
            Pour un praticien européen qui vient démarrer son activité de
            chirurgien dentiste en France, le point de passage prioritaire est
            l’inscription à l’ordre des chirurgiens dentistes du département
            d’exercice. Outre toute une série de documents à fournir, un examen
            sous forme d’entretien avec un jury, permet au conseil de l’ordre
            d’évaluer le niveau de francais du candidat , qui doit être apte à
            bien communiquer avec son patient pour lui expliquer son plan de
            traitement notamment.
          </p>
          <p>
            Nous avons mis au point un programme et un accompagnement qui vise à
            mettre le candidat en situation réelle et de simuler un tel
            entretien, afin de préparer le candidat au mieux.
          </p>
        </div>
      </div>
      <div className={MonStyle.BlocArticles}>
        <div>
          <div className="TitreBleu">LA RECHERCHE D’UN LOGEMENT</div>
          <p>
            Démarrer sa nouvelle activité pour un praticien passe nécessairement
            par la recherche d’un logement. Outre une prise en charge des
            premiers loyers, trouver et obtenir un appartement peut devenir une
            démarche fastidieuse pour nos futurs praticiens. Les bailleurs
            demandent beaucoup de garantie pour les futurs locataires, et nous
            intervenons pour le compte de nos futurs praticiens afin de
            sécuriser les opérations auprès des bailleurs et décrocher un
            logement. Par ailleurs, dans certaines villes, nous avons développé
            un partenariat avec des prestataires.
          </p>
          <p>
            Exemple à Avignon : nous avons la chance de côtoyer Appart’Hotels
            qui propose des formules hôtelières de court et moyen séjour. Nous
            avons négocié des conditions privilégiées pour nos praticiens en
            fonction de leurs besoins.
          </p>
          <a
            href="https://www.apart-hotel-avignon.fr/appartements/"
            target="_blank"
            rel="noreferrer"
          >
            Visiter le site Appart Hotel de Sainte Marthe
          </a>
        </div>

        <div className={MonStyle.GallerieArticle}>
          <img
            className={MonStyle.ImageGallerie}
            src={
              process.env.PUBLIC_URL +
              "/img/IllustrationArticleHebergement_1.jpg"
            }
            alt="Exemple Appart Hotel de Sainte Marthe"
          />{" "}
          <img
            className={MonStyle.ImageGallerie}
            src={
              process.env.PUBLIC_URL +
              "/img/IllustrationArticleHebergement_2.jpg"
            }
            alt="Exemple Appart Hotel de Sainte Marthe"
          />{" "}
          <img
            className={MonStyle.ImageGallerie}
            src={
              process.env.PUBLIC_URL +
              "/img/IllustrationArticleHebergement_3.jpg"
            }
            alt="Exemple Appart Hotel de Sainte Marthe"
          />{" "}
          <img
            className={MonStyle.ImageGallerie}
            src={
              process.env.PUBLIC_URL +
              "/img/IllustrationArticleHebergement_4.jpg"
            }
            alt="Exemple Appart Hotel de Sainte Marthe"
          />{" "}
        </div>
      </div>

      <div className={MonStyle.BlocArticles}>
        <img
          src={IllustrationArticle3}
          className={MonStyle.IllustrationArticle}
          alt=""
        />
        <div>
          <div className="TitreBleu">
            AIDE A L’OUVERTURE D’UN COMPTE BANCAIRE
          </div>
          <p>
            Nous avons négocié auprès de notre partenaire financier bancaire des
            conditions privilégiées concernant un ensemble de services bancaires
            et assurantiels pour tous nos collaborateurs .
          </p>
        </div>
      </div>

      <div className={MonStyle.BlocArticles}>
        <div>
          <div className="TitreBleu">
            ACCOMPAGNEMENT AUX BONNES PRATIQUES MEDICALES
          </div>
          <p>
            SANTEA a créé un poste de directeur des soins depuis maintenant
            quelques années. Outre ses compétences éprouvées notamment auprès du
            service d’odontologie d’hôpitaux d’ile de France, il consacre tout
            son temps à épauler l’ensemble de nos chirurgiens dentistes :
          </p>
          <ul>
            <li>
              A les faire progresser dans leurs pratiques professionnelles
            </li>
            <li>
              A partager et échanger sur des cas cliniques patients complexes{" "}
            </li>
            <li>
              A écouter et partager avec les praticiens sur leurs différents
              besoins, que ce soit en terme de matériels , de besoins de
              formations complémentaires
            </li>
            <p>
              Pour les praticiens et notamment les nouvelles recrues en
              démarrage dans leur activité, cela représente un vrai plus en
              termes de sérénité dans leur activité.
            </p>
            <p>
              Notre directeur des soins passe en moyenne environ 2 fois par mois
              dans les centres pour échanger avec les équipes médicales.
            </p>
          </ul>
        </div>
        <img
          src={IllustrationArticle4}
          className={MonStyle.IllustrationArticleDroite}
          alt=""
        />
      </div>
      <div className={MonStyle.BlocArticles}>
        <img
          src={IllustrationArticle5}
          className={MonStyle.IllustrationArticle}
          alt=""
        />

        <div>
          <div className="TitreBleu">
            FORMATION A LA CCAM ET AUX EVOLUTIONS REGLEMENTAIRES
          </div>
          <p>
            Au sein de SANTEA, nous avons développé un pôle d’expertise au siège
            , avec notamment des compétences pointues sur la nomenclature CCAM
            et NGAP. En effet, les règles de cotation et de facturation dans le
            domaine des spécialités dentaires sont relativement complexes, avec
            beaucoup de subtilités à prendre en compte (règle sur les
            exceptions, les codes associations,….) . Notre experte sur la
            nomenclature visite en permanence les centres pour transmettre son
            savoir à toute les équipes : secrétaires médicales, assistantes et
            praticiens.
          </p>
        </div>
      </div>

      {/* LISTE DES PROCHAINS CENTRES A OUVRIR*/}
      <div className={MonStyle.BlocCentres}>
        <p className="TitreTurquoise">Liste des prochains centres à ouvrir</p>
        {liste.map((centre) => (
          <Centre centre={centre} key={centre.id} />
        ))}
      </div>

      {/* BlOC CONTACTER NOUS*/}
      <div className={MonStyle.BlocContact}>
        <p className="Titre2Blanc">Contactez nous</p>
        <BoutonContact couleur="blanc" style={{ margin: "40px 0px" }} />
      </div>
    </div>
  );
}
