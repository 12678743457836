import React from "react";
import MonStyle from "./PageCentres.module.css";
import InputRecherche from "../Design/Formulaires/InputRecherche";
import Centre from "../Components/Centre";
import { getCentres } from "../Data/DataCentres";
import { useEffect } from "react";
import { useState } from "react";
import IllustrationCentres from "../Images/IllustrationCentres.png";

import IllustrationCentresMobile from "../Images/IllustrationCentresMobile.png";

export default function PageCentres() {
  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [texteRecherche, setTexteRecherche] = useState("");

  useEffect(() => {
    getCentres(texteRecherche).then((data) => setListe(data));
  }, [texteRecherche]);

  return (
    <div>
      {/*  BLOC ILLUSTRATIONS*/}
      <div className={MonStyle.BlocIllustration}>
        <img
          src={IllustrationCentres}
          alt="Centres Santéa"
          className={MonStyle.ImageIllustration}
        />
        <img
          src={IllustrationCentresMobile}
          alt="Centres Santéa"
          className={MonStyle.ImageIllustrationMobile}
        />
        <div className={MonStyle.BlocTitreIllustration}>
          <div className="TitreBlanc">Nos centres dentaires</div>
        </div>
      </div>

      {/*  BLOC URGENCES*/}
      <div className={MonStyle.BlocCentres}>
        <InputRecherche onChange={(texte) => setTexteRecherche(texte)} />
        {liste.length === 0 && (
          <div>
            <div className={MonStyle.MessageAucunResultat}>
              Aucun centre trouvé
            </div>
            <div className={MonStyle.SousTitreMessageAucunResultat}>
              Essayez de modifier votre recherche
            </div>
          </div>
        )}
        <div className={MonStyle.ListeCentres}>
          {liste.map((centre) => (
            <Centre centre={centre} key={centre.id} />
          ))}
        </div>
      </div>
      {/*  BLOC URGENCES*/}
      <div className={MonStyle.BlocUrgence}>
        <div className={MonStyle.TitreUrgence}>Une urgence ?</div>
        <p>
          En cas d'urgence, téléphonez à votre centre dentaire, ou au centre le
          plus proche de chez vous. <br />
          Nous évalurons la nature de l'urgence et nous essayerons de vous
          prendre entre 2 rendez vous.
        </p>
      </div>
    </div>
  );
}
