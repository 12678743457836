import React from "react";
import MonStyle from "./PageTravail.module.css";
import IllustrationMateriel from "../Images/IllustrationMateriel.png";

export default function PageTravail(params) {
  return (
    <div>
      {/* BLOC 1*/}
      <div className={MonStyle.Bloc1}>
        <div className="TitreTurquoise">
          Vos conditions de travail sont importantes
        </div>
        <p>
          L’ensemble des <mark>centres SANTEA</mark> ont été{" "}
          <mark>conçus et réalisés</mark> pour que les
          <mark> conditions de travail</mark> de l’ensemble des collaborateurs
          soient
          <mark> optimales</mark>.
        </p>
      </div>
      <img
        src={IllustrationMateriel}
        alt="matériel utilisé"
        className={MonStyle.ImageBloc1}
      />
      {/* BLOC 2*/}
      <div className={MonStyle.Bloc2}>
        <div className="TitreTurquoise">Une assitante dédiée </div>
        <p>
          Chez Santea,{" "}
          <mark>chaque praticien dispose d’une assistante dédiée</mark>, peu
          importe son expérience ou sa productivité.
        </p>
        <p>
          Nous considérons que <mark>l’interaction et la complicité</mark> entre
          le Chirurgien-Dentiste et l’Assistante Dentaire est{" "}
          <mark>créateur d’une réelle valeur ajoutée.</mark> C’est ainsi que le{" "}
          <mark>travail à quatre mains</mark> a pour objectif d’augmenter autant
          le confort du patient que la qualité des traitements. En effet, le
          travail à quatre mains <mark>diminue</mark> non seulement le temps au
          fauteuil du patient mais aussi{" "}
          <mark>le stress et la fatigue du praticien</mark> qui réalise des
          interventions dentaires parfois compliquées.
        </p>
      </div>
      {/* BLOC 3*/}
      <div className={MonStyle.Bloc3}>
        <div className="TitreTurquoise">Faciliter le back office </div>
        <p>
          La <mark>gestion</mark> et l’accompagnement{" "}
          <mark>administratif et financier</mark> est géré par un{" "}
          <mark>service support</mark> à <mark>l’écoute</mark> et au{" "}
          <mark>service du praticien</mark>. Il est en de même de l’
          <mark>optimisation des agendas</mark> des praticiens.
        </p>
        <p>
          Le <mark>suivi des dossiers</mark> patients, des{" "}
          <mark>prises en charges</mark> (PEC) et de la
          <mark> facturation</mark> est intégralement géré par{" "}
          <mark>l’équipe du service support.</mark>
        </p>
        <p>
          Ainsi, les <mark>praticiens</mark> sont <mark>dédiés</mark>{" "}
          intégralement été{" "}
          <mark>exclusivement aux soins et aux patients.</mark>
        </p>
      </div>
      {/* BLOC 4*/}
      <div className={MonStyle.Bloc4}>
        <div className="TitreTurquoise">Aucun objectif de chiffre </div>
        <p>
          Chez Santea, vous n’aurez{" "}
          <mark>aucune obligation de chiffres d’affaires</mark> ou d’objectifs
          de chiffres d’affaires.{" "}
          <mark>L’absence de pression au quotidien</mark>
          quant à la réalisation d’un chiffre d’affaires minimum est un
          préalable nécessaire à une offre de soins de qualité plaçant le
          <mark> patient au cœur de la pratique dentaire.</mark>
        </p>
        <p>
          Nous privilégions une <mark>intégration progressive</mark> des
          praticiens en fonction de leur profil et de leur expérience.{" "}
          <mark>A la demande du praticien</mark>, un <mark>coaching</mark> au
          fauteuil et une inscription à des modules de
          <mark> formation</mark> est possible.
        </p>
      </div>
    </div>
  );
}
