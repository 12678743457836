import React from "react";
import BoutonActionPrincipale from "./BoutonsActionPrincipale";
import PhoneIcon from "@material-ui/icons/Phone";

export default function BoutonContact({ couleur, style }) {
  return (
    <BoutonActionPrincipale
      couleur={couleur ? couleur : "turquoise2"}
      style={style}
      component="a"
      href="tel:0186659818"
    >
      <PhoneIcon style={{ marginRight: "10px" }} />
      01 86 65 98 18
    </BoutonActionPrincipale>
  );
}
