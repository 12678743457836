import React from "react";
import MonStyle from "./PageAccueil.module.css";
import BoutonActionPrincipale from "../Design/Boutons/BoutonsActionPrincipale";
import Illustration1 from "../Images/Illustration1.jpg";
import Illustration3 from "../Images/Illustration3.jpg";
import Illustration4 from "../Images/Illustration4.jpg";
import Illustration5 from "../Images/Illustration5.jpg";
import Illustration6 from "../Images/Illustration6.jpg";

import Avantage from "../Components/Avantage";
import Service from "../Components/Service";
import BoutonRendezVous from "../Design/Boutons/BoutonRendezVous";
import Partenaires from "../Components/Partenaires";
import BoutonDoctolib from "../Design/Boutons/BoutonDoctolib";
import BoutonTelephoner from "../Design/Boutons/BoutonTelephoner";

export default function PageAccueil(params) {
  return (
    <div>
      {/* BLOC 1bis actualité*/}
      <div className={MonStyle.BlocActualite}>
        <div
          className="TitreBleu"
          style={{ textTransform: "none", fontWeight: "bolder" }}
        >
          {" "}
          <p className="Titre3Noir">Actualités</p>
          Déplacement du centre de Champigny à Saint-Maur
        </div>
        <p>
          SANTEA a décidé de réorganiser son offre de soins sur le département
          du Val de Marne, et de
          <mark>
            {" "}
            transférer l’activité du site de Champigny sur le site voisin de
            St-Maur
          </mark>
          , distant de seulement 1 km et facilement accessible par les
          transports en commun.
        </p>
        <p>
          L’objectif est de <mark>développer le centre de St-Maur </mark>afin
          d’offrir une
          <mark> meilleure qualité de soins</mark> et un{" "}
          <mark>parcours patient d'avantage optimisé.</mark>
        </p>
        <p>
          <mark>
            La continuité des soins est assurée pour l’ensemble des patients du
            centre de Champigny
          </mark>
          , qui ont tous été recontactés pour replanifier les RDV à venir.
        </p>
        <div className={MonStyle.BoutonsActualite}>
          <BoutonTelephoner numero="01 53 99 13 98" />
          <BoutonDoctolib
            url="https://www.doctolib.fr/centre-dentaire/saint-maur-des-fosses/santea-saint-maur-des-fosses"
            style={{ width: "fit-content" }}
          />
        </div>
      </div>
      {/* BLOC 1*/}
      <div className={MonStyle.Bloc1}>
        <img
          src={Illustration1}
          alt="dentiste soignant un enfant"
          className={MonStyle.ImageBloc1}
        />

        <div className={MonStyle.TitreEtBoutonBloc1}>
          <div className="TitreBlanc">La santé dentaire avant tout !</div>

          <BoutonRendezVous
            couleur="vert1"
            //style={{ marginTop: "120px" }}
          />
        </div>
      </div>

      {/* BLOC 2*/}
      <div className={MonStyle.Bloc2}>
        <div className={MonStyle.TitreEtTexteBloc2}>
          <div className="TitreBleu">DES SOINS DE QUALITÉS POUR TOUS !</div>
          <p>
            Nos centres dentaires sont situés dans des villes de moyenne et
            petite taille, pour permettre au plus grand nombre d'accéder à des
            soins dentaires de qualités.
          </p>
          <BoutonActionPrincipale
            style={{ margin: "40px" }}
            link="/centres#top"
          >
            Trouver un centre
          </BoutonActionPrincipale>
        </div>
        <img
          src={Illustration3}
          alt="Dentiste femme"
          className={MonStyle.ImageBloc2}
        />
      </div>

      {/* BLOC 3*/}
      <div className={MonStyle.Bloc3}>
        <div className={MonStyle.AvantagesBloc3}>
          <Avantage titre="La qualité des soins">
            Le <mark>respect strict</mark> des règles de bonne pratique et
            d’éthique de l’exercice dentaire, une <mark>traçabilité</mark> des
            matériaux utilisés et le <mark>suivi des patients</mark> dans la
            durée assurée par des professionnels qui les connaissent bien.
          </Avantage>
          <Avantage titre="La transparence">
            Le <mark>patient</mark> est placé au cœur du parcours de soins avec
            une <mark>information claire, complète et compréhensible</mark> sur
            les choix thérapeutiques proposés par son Chirurgien-dentiste.
          </Avantage>
          <Avantage titre="Une prise en charge administrative complète">
            Un <mark>accompagnement administratif et financier</mark> tout au
            long du parcours patient. De la prise de RDV jusqu’au tiers-payant
            intégral, nous réalisons{" "}
            <mark>toutes les démarches à votre place</mark> mais jamais sans
            votre accord.
          </Avantage>
          <Avantage titre="La formation continue">
            La <mark>formation continue des praticiens</mark> avec des sessions
            complètes de formation sont organisées <mark>en interne.</mark>{" "}
            Notre objectif : avoir des chirurgiens-dentistes constamment formés
            aux <mark>dernières techniques dentaires.</mark>
          </Avantage>
          <Avantage titre="Innovation continue">
            <mark>Innovation continue</mark> sur les pratiques professionnelles,
            les matériels de nos partenaires <mark>pour que nos patients</mark>{" "}
            puissent <mark>en bénéficier.</mark> Ainsi, nos centres disposent de
            <mark>
              {" "}
              tous les équipements à la pointe (fauteuils, radio, pano, 3D, Cone
              Beam, etc…)
            </mark>
          </Avantage>

          <Avantage titre="Bienveillance partagée">
            Un groupe à <mark>taille humaine</mark> avec des{" "}
            <mark>valeurs de bienveillance </mark>
            partagée pour nos collaborateurs et patients.
          </Avantage>
        </div>
        <BoutonRendezVous couleur="turquoise" />
      </div>

      {/* BLOC 4*/}
      <div className={MonStyle.Bloc4}>
        <div className="TitreBleu">
          Nous répondons à vos besoins et à vos attentes
        </div>
        <div className={MonStyle.ServicesBloc4}>
          <Service image={Illustration4} titre="Soins dentaires">
            Chez Santea, la{" "}
            <mark>priorité est donnée aux soins conservateurs </mark>
            (traitement des caries, détartrages, plombage ou, composites, etc)
            <mark> et à la prévention</mark> des maladies des dents et des
            gencives.
          </Service>
          <Service image={Illustration5} titre="Prothèses dentaires">
            Lorsque les <mark>soins conservateurs ne sont plus possibles</mark>,
            il est <mark>nécessaire de remplacer des dents</mark> manquantes ou
            défectueuse. Il doit être envisagé la pose d’une{" "}
            <mark>prothèse dentaire </mark>
            (inlays, onlays, couronnes, bridges, prothèses partielles ou
            totales, fixes ou amovibles). En plus du{" "}
            <mark>caractère esthétique</mark>, une prothèse dentaire peut
            permettre{" "}
            <mark>d’éviter des douleurs articulaires et musculaires.</mark>
          </Service>
          <Service image={Illustration6} titre="Orthodontie">
            Cette <mark>spécialité dentaire </mark>permet avec un grand choix de
            traitement de <mark>retrouver un alignement dentaire </mark>et ainsi
            <mark> corriger des gènes au quotidien </mark>: mauvaise mastication
            (et donc problème de digestion) ; difficulté d’élocution ou
            d’articulation ; problème de respiration (apnée du sommeil).
          </Service>
        </div>
        <BoutonActionPrincipale
          couleur="bleu1"
          style={{ margin: "40px" }}
          link={"/services#soins"}
        >
          Voir tous les services
        </BoutonActionPrincipale>
      </div>
      {/* BLOC PARTENAIRES*/}
      <div className={MonStyle.BlocPartenaires}>
        <div className="TitreGris" style={{ textAlign: "center" }}>
          Nos partenaires
        </div>
        <Partenaires style={{ margin: "20px 0px" }} />
      </div>
    </div>
  );
}
