import React from "react";
import CheckIcon from "@material-ui/icons/Check";
export default function Avantage({ titre, description, style, ...props }) {
  return (
    <div style={{ display: "flex", ...style }}>
      <div
        style={{
          color: "#2db7bc",
          backgroundColor: "#2db7bc20",
          minWidth: "38px",
          height: "38px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20px",
          marginRight: "10px",
        }}
      >
        <CheckIcon />
      </div>
      <div>
        <p className="Titre2">{titre}</p>
        <div style={{ textAlign: "justify" }}>{props.children}</div>
      </div>
    </div>
  );
}
