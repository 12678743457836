import React from "react";
import MonStyle from "./MenuMobile.module.css";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import MotifBleu from "../Images/MotifBleu.png";
import BoutonDoctolib from "../Design/Boutons/BoutonDoctolib";

export default function MenuMobile() {
  //NES gestion du state
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <div className={MonStyle.PanneauMenu}>
          <div className={MonStyle.EntetePanneauMenu}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
              <div
                style={{
                  marginLeft: "20px",
                  color: "#666666",
                  fontWeight: "300",
                }}
              >
                MENU
              </div>
            </IconButton>
          </div>

          <BoutonDoctolib style={{ marginTop: "40px" }} />
          <Divider style={{ margin: "30px 0px 0px 0px" }} />

          <List className={MonStyle.Menu} onClick={() => setOpen(false)}>
            <ListItem component={Link} to="/" className={MonStyle.MenuItem}>
              Accueil
            </ListItem>
            <ListItem
              component={Link}
              to="/services"
              className={MonStyle.MenuItem}
            >
              Services et tiers payant
            </ListItem>
            <ListItem
              component={Link}
              to="/centres"
              className={MonStyle.MenuItem}
            >
              Nos centres dentaires
            </ListItem>
            <Divider style={{ margin: "20px 0px" }} />
            <ListItem
              component={Link}
              to="/professionnels/valeurs"
              className={MonStyle.MenuItemProfessionnel}
            >
              Vous êtes un professionnel ?
            </ListItem>
          </List>
          <img src={MotifBleu} style={{ height: "80px" }} alt="" />
        </div>
      </SwipeableDrawer>
    </div>
  );
}
