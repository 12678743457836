import React from "react";
import MonStyle from "./PiedPage.module.css";
import LogoBlanc from "../Images/LogoBlanc.svg";
import MotifLogo from "../Images/MotifLogo.png";
import { HashLink as Link } from "react-router-hash-link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export default function PiedPage() {
  return (
    <div className={MonStyle.PiedPage}>
      <div className={MonStyle.LogoEtTexte}>
        <img src={LogoBlanc} alt="LogoBlanc" />
        <a
          href="https://www.linkedin.com/company/espaces-dentaires-santea/"
          target="_blank"
          rel="noreferrer"
        >
          <div className={MonStyle.BoutonLinkedin}>
            <LinkedInIcon style={{ fontSize: 50 }} /> Retrouvez nous sur
            Linkedin
          </div>
        </a>

        <div className={MonStyle.Texte}>
          En réunissant technologie de pointe, expertise dentaire et
          orthodontique dans un seul et même lieu, les espaces dentaires Santéa
          répondent à vos besoins spécifiques avec flexibilité et
          professionnalisme.
        </div>
        <div className={MonStyle.Copyright}>
          Copyright Santéa France 2022 - Tous droits réservés.
        </div>
        <div className={MonStyle.BlocMentions}>
          <div className={MonStyle.Atelier}>
            Site réalisé par l'Atelier du Pixel
          </div>
          <Link to="/mentionslegales#top">
            <div className={MonStyle.MentionsLegales}>Mentions légales</div>
          </Link>
        </div>
      </div>

      <img src={MotifLogo} alt="motif logo" className={MonStyle.LogoBlanc} />
    </div>
  );
}
