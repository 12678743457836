import React from "react";
import MonStyle from "./Menu.module.css";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export default function Menu() {
  //NES gestion du style
  const ItemMenu = withStyles((props) => ({
    root: {
      boxShadow: "none",
      textTransform: "none",
      fontSize: 18,
      padding: "6px 12px",
      lineHeight: 1.5,
      backgroundColor: (props) => (props.selected ? "#004D86" : "#005799"),
      fontFamily: "Lato",
      borderRadius: "0px",
      color: "#FFFFFF",
      opacity: (props) => (props.selected ? 1 : 0.8),
      textDecoration: (props) => (props.selected ? "underline" : "none"),
      textUnderlineOffset: "5px",

      "&:hover": {
        textDecoration: "underline",
        backgroundColor: "#00000020",
        borderColor: "#0062cc",
        boxShadow: "none",
        color: "white",
        opacity: 1,
      },
      "&:active": {},
      "&:focus": {},
    },
  }))(Button);

  const ItemMenuProfessionnel = withStyles((props) => ({
    root: {
      boxShadow: "none",
      textTransform: "none",
      fontSize: 18,
      padding: "6px 12px",
      lineHeight: 1.5,
      //opacity: (props) => (props.selected ? 1 : 0.9),
      backgroundColor: "#2DB7BC",
      fontFamily: "Lato",
      borderRadius: "0px",
      color: "#FFFFFF",
      textUnderlineOffset: "5px",

      textDecoration: (props) => (props.selected ? "underline" : "none"),
      "&:hover": {
        textDecoration: "underline",
        backgroundColor: "#2DB7BC",
        boxShadow: "none",
        color: "white",
        opacity: 1,
      },
      "&:active": {},
      "&:focus": {},
    },
  }))(Button);

  //NES Gestion du state
  const [value, setValue] = useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className={MonStyle.Menu}>
      <ItemMenu
        className={MonStyle.ItemMenu}
        onClick={() => handleChange(0)}
        selected={value === 0 ? true : false}
        component={Link}
        to="/"
      >
        Accueil
      </ItemMenu>
      <ItemMenu
        className={MonStyle.ItemMenu}
        onClick={() => handleChange(1)}
        selected={value === 1 ? true : false}
        component={Link}
        to="/services"
      >
        Services et tiers payant
      </ItemMenu>
      <ItemMenu
        onClick={() => handleChange(2)}
        selected={value === 2 ? true : false}
        className={MonStyle.ItemMenu}
        component={Link}
        to="/centres"
      >
        Nos centres dentaires
      </ItemMenu>
      <ItemMenuProfessionnel
        onClick={() => handleChange(3)}
        selected={value === 3 ? true : false}
        className={MonStyle.ItemMenu}
        component={Link}
        to="/professionnels/valeurs"
      >
        Vous êtes un professionnel ?
      </ItemMenuProfessionnel>
    </div>
  );
}
