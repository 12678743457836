import React from "react";
import MonStyle from "./PageMentionsLegales.module.css";

export default function PageMentionsLegales() {
  return (
    <div className={MonStyle.PageMentionsLegales}>
      <div className="TitreBleu">Mentions Légales</div>
      <article>
        <p className="Titre2Vert">Editeur du site</p>
        <p>
          Le Site est détenu par la société l'Atelier du Pixel, société par
          actions simplifiée au capital de 14.000 euros, dont le siège social
          est à Liverdun, 10 rue porte haute, immatriculée au registre du
          commerce et des sociétés de Nancy sous le numéro 901 628 370 RCS
          Nancy. L'Atelier du Pixel agit pour le compte des centres dentaires
          exerçant sous l’enseigne SANTEA.{" "}
        </p>
        <p>Contact : contact@santea-france.com</p>
      </article>

      <p className="Titre2Vert">Hébergeur du site</p>
      <p>
        Le Site est hébergé par OVH, dont le siège social est situé 2 rue
        Kellermann, 59100 Roubaix. Pour plus d’informations sur l’hébergeur :
        https://www.ovh.com/fr/
      </p>
      <article>
        <p className="Titre2Vert">Accès au site</p>
        <p>
          L’accès au Site et son utilisation sont réservés strictement à un
          usage personnel. Vous vous engagez à ne pas utiliser ce Site et les
          informations ou données qui y figurent à des fins commerciales,
          politiques, publicitaires et pour toute autre forme de sollicitation
          commerciale et notamment l’envoi de courriers électroniques non
          sollicités.
        </p>
        <p>
          Nous rappelons que le fait d’accéder ou de se maintenir
          frauduleusement dans un système informatique, d’entraver ou de fausser
          le fonctionnement d’un tel système, d’introduire ou de modifier
          frauduleusement des données dans un système informatique constituent
          des délits passibles de sanctions pénales.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">
          Contenu du site et droit de propriété intellectuelle
        </p>
        <p>
          Toutes les marques, photographies, textes, dessins, commentaires,
          illustrations, images animées ou non, séquences vidéo, sons, ainsi que
          toutes les applications informatiques et éléments de reproduction
          utilisés pour faire fonctionner ce Site sont protégés par les lois en
          vigueur notamment l’article L. 111-1 et suivants du code de la
          propriété intellectuelle. Les logos, icônes et puces graphiques
          représentés sur ce Site sont protégés au titre des droits d’auteur et
          des articles L. 511-1 et suivants du code de la propriété
          intellectuelle relatifs à la protection des modèles déposés. Les
          reproductions, sur un support papier ou informatique, dudit Site et
          des œuvres qui y sont reproduits sont autorisées sous réserve qu’elles
          soient strictement réservées à un usage personnel excluant tout usage
          à des fins publicitaires et/ou commerciales et/ou d’information et/ou
          qu’elles soient conformes aux dispositions de l’article L. 122-5 du
          code de la propriété intellectuelle.
        </p>
        <p>
          L’ensemble des éléments du contenu du Site (textes, images, données,
          dessins, graphiques, photos et bandes sonores…) et l’ensemble des
          éléments relatifs à sa forme (choix, plan, disposition des matières,
          moyens d’accès aux données, organisation des données…) sont la
          propriété entière et exclusive de l'Atelier du Pixel ou de ses
          partenaires. Toute reproduction, représentation, adaptation,
          utilisation ou modification, par quelque procédé que ce soit et sur
          quelque support que ce soit, de tout ou partie du Site, de tout ou
          partie des différentes œuvres qui le composent, sans avoir obtenu
          l’autorisation préalable expresse et écrite de L'Atelier du Pixel, est
          strictement interdite et constitue un délit de contrefaçon sanctionné
          par les articles L. 335-2 et suivants du code de la propriété
          intellectuelle. Par exception, certains contenus (textes, images) sont
          la propriété de leurs auteurs respectifs.
        </p>
        <p>
          Les marques présentes sur le Site notamment la marque L'Atelier du
          Pixel et celles des partenaires de L'Atelier du Pixel, ainsi que les
          logos figurant sur le Site sont des marques déposées en France et/ou à
          l’International sous différentes déclinaisons. Toute reproduction
          intégrale ou partielle de ces marques ou de ces logos, effectuée à
          partir des éléments du Site sans l’autorisation préalable, expresse et
          écrite de L'Atelier du Pixel, est interdite, au sens de l’article L.
          713-2 du code de la propriété intellectuelle. Toute utilisation du
          contenu du Site à des fins illégales fera l’objet de poursuites
          judiciaires à l’égard des contrevenants.
        </p>
        <p>
          Les photographies de produits, accompagnant leur description, ne sont
          pas contractuelles et n’engagent pas l’éditeur du Site. Elles sont
          délivrées uniquement à titre indicatif.
        </p>
      </article>

      <article>
        <p className="Titre2Vert">Gestion du site </p>
        Pour la bonne gestion du Site, L'Atelier du Pixel peut :
        <ul>
          <li>
            Suspendre, interrompre ou limiter l’accès à tout ou partie du Site{" "}
          </li>
          <li>
            Réserver l’accès au Site, à une certaine partie ou à une catégorie
            déterminée d’internautes{" "}
          </li>
          <li>
            Supprimer toutes les informations pouvant perturber le
            fonctionnement ou entrant en contravention avec les lois nationales
            ou internationales ou avec les règles de la Nétiquette ; Suspendre
            le Site afin de procéder à des mises à jour.{" "}
          </li>
        </ul>
      </article>

      <article>
        <p className="Titre2Vert">Contenu du Site et responsabilité</p>
        <p>
          L'Atelier du Pixel s’efforce d’assurer l’exactitude, la complétude et
          la mise à jour des informations publiées sur le Site. L'Atelier du
          Pixel se réserve le droit de modifier, à tout moment et sans
          information préalable, le contenu ou la présentation du Site.
          L’exhaustivité ou l’absence de modification par un tiers (intrusion,
          virus) ne peuvent être garantis.
        </p>
        <p>
          L'Atelier du Pixel s’efforce également de fournir un environnement de
          sécurité adéquat dans l’utilisation du Site. Le Site est protégé avec
          une connexion HTTPS. L'Atelier du Pixel n’est pas responsable des
          erreurs, omissions concernant le contenu du Site. L'Atelier du Pixel
          n’est pas responsable des éventuelles interruptions ou
          indisponibilités du présent Site.
        </p>
        <p>
          Toute décision prise à partir des informations ou de l’utilisation du
          Site engagera uniquement son auteur, L'Atelier du Pixel n’est en aucun
          cas responsable de ces décisions, de façon directe ou indirecte, ni
          des dommages, directs ou indirects, qui en découleraient. L'Atelier du
          Pixel se dégage également de toute responsabilité découlant de la
          transmission d’informations ou de données identifiées comme
          confidentielles par un tiers sur le réseau internet.
        </p>
        <p>
          L'Atelier du Pixel n’est pas responsable des dommages causés à
          vous-même, à des tiers et/ou à votre équipement du fait de votre
          connexion ou de votre utilisation de son Site, et vous renoncez à
          toute action contre L'Atelier du Pixel de ce fait. Si une procédure
          amiable ou judiciaire à raison de votre utilisation du Site, L'Atelier
          du Pixel pourra se retourner contre vous pour obtenir l’indemnisation
          de tous les préjudices, sommes, indemnités, condamnations et tout
          frais qui pourraient découler de cette procédure.
        </p>
        <p>
          Le matériel de connexion (ordinateur, logiciels, équipements réseaux –
          modems, téléphones…) au Site que vous utilisez est sous votre entière
          responsabilité. Nous vous prions de bien vouloir prendre toutes les
          mesures nécessaires et appropriées pour protéger votre matériel et vos
          propres données notamment des incidents d’attaques virales Internet.
          Vous êtes par ailleurs seul responsable des sites et données que vous
          consultez.
        </p>
        <p>
          L'Atelier du Pixel ne pourra être tenu responsable en cas de
          poursuites judiciaires à votre encontre :
        </p>
        <ul>
          <li>
            Du fait de l’usage du Site ou de tout service accessible via
            Internet.
          </li>
          <li>
            Du fait du non-respect par vous des présentes conditions générales
            d’utilisation.
          </li>
        </ul>
        <p>
          La responsabilité de L'Atelier du Pixel ne peut être engagée qu’en cas
          de défaillance, panne, difficultés ou interruption de fonctionnement,
          empêchant l’accès à son Site ou à l’une de ses fonctionnalités.
        </p>
        <p>
          L’utilisateur du Site est seul responsable des éventuelles
          contaminations par virus circulant sur le réseau internet.
          L’utilisateur doit prendre toutes les mesures adéquates et appropriées
          pour protéger ses données personnelles et ses logiciels d’une telle
          contamination.
        </p>
      </article>

      <article>
        <p className="Titre2Vert">Liens hypertextes </p>
        <p>
          La mise en place par les utilisateurs de tous liens hypertextes vers
          tout ou partie du Site est strictement interdite, sauf autorisation
          préalable, expresse et écrite de L'Atelier du Pixel. Nous sommes
          libres de refuser une autorisation de lien hypertexte sans avoir à
          justifier de quelque manière que ce soit notre décision. Dans le cas
          où vous avez obtenu notre autorisation, celle-ci ne sera dans tous les
          cas que temporaire et pourra être retirée à tout moment, sans
          obligation de motivation de la décision de retrait. L'Atelier du Pixel
          n’est pas responsable des liens hypertextes pointant vers le présent
          Site. Les liens hypertextes établis en direction d’autres sites à
          partir du Site SANTEA ne sauraient, en aucun cas, engager la
          responsabilité de L'Atelier du Pixel ou des centres SANTEA notamment
          quant au contenu et à la sécurité de ces sites.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">Utilisation de vos données personnelles</p>
        <p>
          Vous vous engagez à ne pas transmettre des informations
          confidentielles au moyen du Site. Vous reconnaissez, que dans le cadre
          de l’utilisation du Site, ne pas transmettre des informations
          diffamatoires, contraires à la morale ou à l’ordre public, déloyales
          ou appartenant à un tiers. L'Atelier du Pixel se dégage de toute
          responsabilité en cas de manquement à ces obligations de votre part.
          Les données personnelles collectées et traitées, participent à
          l’amélioration des services du Site et de son maintien dans un
          environnement sécurisé. Nous traitons vos données personnelles dans le
          cadre suivant :
        </p>
        <ul>
          <li>
            Accès et utilisation du Site : Vérification, identification et
            authentification des données transmises
          </li>
          <li>Gestion des éventuels litiges avec les utilisateurs</li>
        </ul>
      </article>
      <article>
        <p className="Titre2Vert">Protection des données personnelles</p>
        <p>
          Les informations personnelles pouvant être recueillies sur notre site
          sont principalement utilisées pour la gestion des relations avec vous,
          et le cas échéant pour le traitement de vos prises en charge, pour
          l’amélioration de notre Site, pour nous contacter.
        </p>
        Retrouvez tous les détails de la protection de vos données personnelles
        et de vos droits dans notre politique de protection des données
        personnelles. Vous pouvez exercer vos droits en contactant notre délégué
        à la protection des données :
        <ul>
          <li>
            {" "}
            Par courrier : SANTEA – à l’attention du Délégué à la Protection des
            Données – 12, avenue des Coquelicots – 94380 Bonneuil-sur-Marne
          </li>
          <li>Par email : rgpd@santea-france.com</li>
        </ul>
        <p>
          Votre demande doit être accompagnée d’une photocopie de votre pièce
          d’identité en cours de validité et de l’adresse à laquelle nous
          pourrons vous contacter. Une réponse vous sera adressée dans un délai
          d’un mois suivant la réception de votre demande. Ce délai d’un mois
          peut être prolongé de deux mois en fonction de la complexité de votre
          demande.
        </p>
        <p>
          Particularité : Pour exercer un droit au nom d’une personne mineure,
          sous tutelle ou curatelle, ou décédée, vous pouvez nous envoyer la
          demande d’exercice des droits, en mentionnant que vous êtes un ayant
          droit, le représentant légal, une représentation de tutelle ou de
          curatelle de cette personne, accompagnée d’une preuve de cette
          représentation légale.
        </p>
        <p>
          Conformément à la loi n°2016-1321 du 7 octobre 2016, les personnes qui
          le souhaitent, ont la possibilité d’organiser le sort de leurs données
          après leur décès. Pour plus d’information sur le sujet, vous pouvez
          consulter le site internet de la CNIL : https://www.cnil.fr/.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">
          Votre consentement à l’utilisation des cookies
        </p>
        <p>
          En naviguant sur ce Site, des « cookies » émanant de la société
          responsable du Site concerné et/ou des sociétés tierces pourront être
          déposés sur votre terminal. Lors de la première navigation sur ce
          Site, une bannière explicative sur l’utilisation des « cookies »
          apparaîtra en haut de la page de votre navigateur. Dès lors, en
          poursuivant la navigation, le client et/ou prospect sera informé et
          pourra accepter l’utilisation desdits « cookies » en ayant le choix
          des préférences relatives à cette utilisation faisant ainsi acte de
          consentement. Le consentement donné sera valable pour une période de
          treize (13) mois. Vous avez la possibilité de désactiver les cookies à
          partir des paramètres de votre navigateur.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">
          Les finalités de l’utilisation des cookies et protection de vos
          données personnelles concernées par les cookies{" "}
        </p>
        <p>
          Les finalités relatives à l’utilisation de cookies sont déterminées,
          explicites et légitimes. Toutes les informations collectées à travers
          l’utilisation des cookies ne seront utilisées que pour suivre le
          volume, le type et la configuration du trafic utilisant ce Site, pour
          en développer la conception et l’agencement et à d’autres fins
          administratives et de planification et plus généralement pour
          améliorer le service que nous vous offrons. Le traitement de ces
          informations ne fait pas l’objet de décision automatisée.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">
          Les cookies suivants sont présents sur ce Site :
        </p>
        <p>Aucun cookie n'est utilisé sur ce site</p>
      </article>
      <article>
        <p className="Titre2Vert">
          Modification et mise à jour des conditions générales d’utilisation
        </p>
        <p>
          L'Atelier du Pixel se réserve le droit de réviser, pour quelque motif
          que ce soit, les présentes conditions générales d’utilisation à tout
          moment et sans préavis. En cas de révision des présentes Conditions,
          L'Atelier du Pixel s’engage à publier les modifications sur son Site,
          et à indiquer clairement et visiblement la date de mise à jour. Votre
          utilisation du Site vaudra acceptation des présentes Conditions
          révisées.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">
          Prévention et règlement amiable des conflits
        </p>
        <p>
          Afin de prévenir d’éventuels litiges, le moindre désaccord dans
          l’interprétation, l’exécution des présentes Conditions fera l’objet de
          discussions préalables afin d’éviter les conflits et tout contentieux.
        </p>
        <p>
          En cas de litige persistant, les parties aux présentes Conditions
          s’engagent à privilégier, en amont de tout contentieux, un règlement à
          l’amiable dans la limite des volontés respectives de chacune d’entre
          elles.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">Loi applicable </p>
        <p>
          Les présentes conditions d’utilisation du Site sont et seront régies
          par la loi française et soumises à la compétence des tribunaux de
          commerce de Nancy, même en cas de pluralité des défendeurs, d’appel en
          garantie ou de procédure en référé. Si une juridiction compétente
          déclare une clause des présentes Conditions nulle ou invalide, cette
          clause ne sera invalidée que dans la mesure du strict nécessaire sans
          affecter la validité globale de ces Conditions qui demeureront tout de
          même en vigueur.
        </p>
      </article>
      <article>
        <p className="Titre2Vert">Contactez-nous </p>
        <p>
          Pour toute question, information sur les produits présentés sur le
          Site, ou concernant le site lui-même, vous pouvez nous laisser un
          message à l’adresse email suivante : rgpd@santea-france.com
        </p>
      </article>
    </div>
  );
}
