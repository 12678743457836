import React from "react";
import MonStyle from "./PageValeurs.module.css";
import Illustration10 from "../Images/Illustration10.jpg";
import Illustration11 from "../Images/Illustration11.jpg";
import BoutonActionPrincipale from "../Design/Boutons/BoutonsActionPrincipale";
import BoutonContact from "../Design/Boutons/BoutonContact";
import Partenaires from "../Components/Partenaires";
import ReactPlayer from "react-player";

export default function PageValeurs(params) {
  return (
    <div>
      {/* BLOC SOINS */}
      <div className={MonStyle.BlocSoins}>
        <div className="TitreTurquoise">LA QUALITE DES SOINS AVANT TOUT</div>
        <p>
          Notre raison d’être : offrir une{" "}
          <mark>qualité de soins optimale</mark> et pour le{" "}
          <mark>plus grand nombre, </mark>
          car aujourd’hui encore, le renoncement aux soins dentaires est une
          réalité en France. Cela se traduit par l’accueil de tout type de
          population, sans aucune forme de discrimination.
        </p>
        <p>
          La <mark>prise de rendez-vous </mark>est gérée au niveau du
          secrétariat sans validation particulière du praticien. La gestion des
          <mark> urgences </mark>fait aussi partie de notre offre de soins
          standard. Notre politique tarifaire vise à pratiquer des
          <mark> tarifs modérés pour tous.</mark>
        </p>
        <p>
          La pratique <mark> systématique du tiers-payant </mark>permet la
          plupart du temps d’<mark>éviter toute avance de frais </mark> pour le
          patient Nos <mark> praticiens </mark>ne subissent{" "}
          <mark> aucune pression managériale </mark>pour atteindre des objectifs
          de Chiffre d’Affaires. Tous les <mark> professionnels </mark>sont donc
          alignés avec nos valeurs d’entreprise: la{" "}
          <mark> QUALITE DES SOINS !</mark>
        </p>

        {/* BLOC VIDEO PATRICK */}
        <div className={MonStyle.BlocVideo}>
          <ReactPlayer
            url="https://youtu.be/sF2EXSowiM4"
            style={{ maxWidth: "100%" }}
          />
          <p>
            Découvrez les valeurs de Santéa, lors d'une interview de Patrick
            GIUNTA, co-fondateur de Santéa.
          </p>
        </div>
      </div>
      {/* BLOC FORMATION */}
      <div className={MonStyle.BlocFormation}>
        <div className={MonStyle.ImageEtTexteFormation}>
          <img
            src={Illustration10}
            alt="Equipe de formation"
            className={MonStyle.ImageFormation}
          />
          <div>
            <div className="TitreTurquoise">L'importance de la formation</div>
            <p>
              Les <mark>pratiques professionnelles</mark> de l’art dentaire
              <mark> évoluent </mark> sans cesse. Nos professionnels, les
              dentistes, sont en attente d’être{" "}
              <mark>formés régulièrement </mark>aux métiers de l’omni-pratique,
              mais aussi de toutes les différentes spécialités (implantologie,
              parodontologie, orthodontie, …). Le{" "}
              <mark> contenu pédagogique </mark>de ces formations est sous la
              responsabilité de notre <mark> directeur des soins.</mark>
            </p>
            <p>
              L’avantage de ces <mark> sessions de formation </mark> groupe
              permet aux praticiens de nos différents centres, non seulement, de{" "}
              <mark> se former,</mark>
              mais aussi de se connaître afin de <mark> partager </mark> sur
              leur <mark> pratique </mark>et leurs{" "}
              <mark> expériences professionnelles.</mark>
            </p>
          </div>
        </div>

        <BoutonActionPrincipale
          couleur="vert2"
          style={{ marginTop: "70px", marginBottom: "30px" }}
          link="/professionnels/formations#formations"
        >
          Découvrez notre programme de formation
        </BoutonActionPrincipale>
      </div>
      {/* BLOC ACCOMPAGNEMENT */}
      <div className={MonStyle.BlocAccompagnement}>
        <div className={MonStyle.TexteEtImageAccompagnement}>
          <div>
            <div className="TitreTurquoise">Nous vous accompagnons </div>
            <p>
              Vous êtes chirurgien-dentiste diplômé au sein de l’Union
              européenne et <mark> souhaitez exercer en France ?</mark>
            </p>
            <p>
              <mark>Santea </mark>vous propose de{" "}
              <mark>vous accompagner, </mark>
              de vous <mark>intégrer </mark>et de vous <mark>soutenir</mark>{" "}
              dans votre <mark> projet de changement de vie.</mark>
            </p>
            <p>
              <mark>Notre implantation géographique</mark> de notre réseau nous
              permet de vous proposer une{" "}
              <mark>diversité d’opportunités professionnelles, </mark>
              correspondant à vos attentes.
            </p>
            <p>
              Cet accompagnement sur mesure consistera notamment à vous{" "}
              <mark>assister lors de votre inscription à l’Ordre</mark> et vous{" "}
              <mark>prépare à la validation</mark> de la pratique de la{" "}
              <mark>langue française</mark> auprès du Conseil de l’Ordre
              Français. <mark>Santea prendra ainsi en charge</mark> votre
              formation par un professeurs de français spécialisés dans le
              passage à l’examen du Conseil de l’Ordre.
            </p>
            <p>
              Santea vous accompagnera
              <mark> également dans votre intégration administrative </mark> sur
              le territoire Français : logement de fonction, certificat
              d’hébergement, aide à l’ouverture d’un compte bancaire, recherche
              de logement, etc…
            </p>
            <div className={MonStyle.BoutonAccompagnement}>
              <BoutonActionPrincipale
                style={{ marginTop: "40px", marginBottom: "30px" }}
                link="/professionnels/accompagnement#top"
              >
                Découvrez notre accompagnement
              </BoutonActionPrincipale>
            </div>
          </div>
          <img
            src={Illustration11}
            alt="Equipe d'accompagnement"
            className={MonStyle.ImageAccompagnement}
          />
        </div>
      </div>

      {/* BLOC Travail d'équipe */}
      <div className={MonStyle.BlocEquipe}>
        <div className="TitreTurquoise">Donner du sens au travail d'équipe</div>
        <p>
          Santea prône le <mark>« bon vivre »</mark> et{" "}
          <mark>l’esprit d’équipe</mark> autour de valeurs communes. Les équipes
          exercent dans un <mark>cadre agréable</mark>,{" "}
          <mark>bienveillant</mark> et dans le souci du
          <mark> partage</mark>, de <mark>l’entraide</mark> et de la{" "}
          <mark>solidarité</mark>.
        </p>
        <p>
          La <mark>continuité́ des soins</mark> en l’absence du
          chirurgien-dentiste qui suit habituellement le patient. C’est ainsi
          que l'<mark>informatisation</mark>, la
          <mark> digitalisation</mark> et le <mark>partage des dossiers</mark>{" "}
          dentaires sont mis en œuvre de manière systématique.
        </p>
        <p>
          Par ailleurs, les <mark>assistants dentaires</mark>, désormais
          reconnus professionnels de santé́, sont{" "}
          <mark>
            pleinement associés au fonctionnement du centre et à son évolution.
          </mark>
        </p>
        <p>
          La <mark>fonction de coordination</mark> est organisée : présence d’un
          responsable identifié et planification de réunions portant sur
          l’organisation interne des soins ou sur des dossiers de cas complexes
          en{" "}
          <mark>
            association avec le Directeur de Soins et le praticien référent du
            centre.
          </mark>
        </p>
        <p>
          Un praticien qui a confiance en son équipe – tant administrative que
          médicale –n’a aucune appréhension du temps. Au contraire, il exerce
          sasérénité et calme
          <mark> sérénité et calme</mark>.
        </p>
        <div className={MonStyle.BoutonCentre}>
          <BoutonActionPrincipale
            style={{ margin: "40px 0px" }}
            link="/professionnels/travail#top"
          >
            Découvrez les conditions de travail
          </BoutonActionPrincipale>
        </div>
      </div>

      {/* BLOC Partenaires */}
      <div className={MonStyle.BlocPartenaires}>
        <div className="TitreTurquoise">Nos partenaires</div>
        <Partenaires />
      </div>

      {/* BLOC CONTACT */}
      <div className={MonStyle.BlocContact}>
        <p className="TitreTurquoise">Contactez nous</p>
        <BoutonContact />
      </div>
    </div>
  );
}
