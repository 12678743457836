import React from "react";
import Formation from "./Formation";
import MonStyle from "./PlanningFormations.module.css";
import Illustration15 from "../Images/Illustration15.jpg";
//import Illustration16 from "../Images/Illustration16.jpg";
import Illustration17 from "../Images/Illustration17.jpg";
import Illustration18 from "../Images/Illustration18.jpg";

export default function PlanningFormationsCouleur() {
  return (
    <div className={MonStyle.PlanningFormations}>
      <div className={MonStyle.Jour}>
        <div className={MonStyle.NomJour}>Lundi</div>
        <Formation heureDebut="9:30" hauteur={1} color="#677A09">
          Présentation des <mark>valeurs de SANTEA</mark>
        </Formation>
        <Formation heureDebut="10:00" hauteur={2} color="#29767D">
          Présentation des outils informatique : imagerie et logiciel de
          facturation
        </Formation>
        <Formation heureDebut="11:00" hauteur={2} color="#1D5A9E">
          Introduction à la nomenclature
        </Formation>
        <Formation heureDebut="13:00" hauteur={1} color="#5D5D5C">
          PAUSE DEJEUNER
        </Formation>
        <Formation heureDebut="14:00" hauteur={2} color="#1D5A9E">
          Établissement des<mark> plans de traitement</mark> (bases) Étude de
          plusieurs cas clinique
        </Formation>
        <Formation heureDebut="16:00" hauteur={2} color="#1D5A9E">
          <mark>Diagnostics</mark> et importance de la <mark>pano</mark> avant
          toute consultation
        </Formation>
      </div>

      <div className={MonStyle.Jour}>
        <div className={MonStyle.NomJour}>Mardi</div>
        <Formation
          heureDebut="9:30"
          hauteur={5}
          imageTop={Illustration15}
          color="#1D5A9E"
        >
          Endodontie Travaux pratiques sur simulateur et dent frasaco Laser
        </Formation>
        <Formation heureDebut="13:00" hauteur={1} color="#5D5D5C">
          PAUSE DEJEUNER
        </Formation>
        <Formation heureDebut="14:00" hauteur={3} color="#1D5A9E">
          Dentaire - Esthétique : Éclaircissement
        </Formation>
        <Formation heureDebut="17:00" hauteur={1} color="#1D5A9E">
          Dentaire - Esthétique : Acide – hyaluronique
        </Formation>
      </div>

      <div className={MonStyle.Jour}>
        <div className={MonStyle.NomJour}>Mercredi</div>
        <Formation heureDebut="9:30" hauteur={1} color="#677A09">
          Présentation des protocoles cliniques
        </Formation>
        <Formation heureDebut="10:00" hauteur={4} color="#1D5A9E">
          Dentaire - Esthétique : - Collage / Facette - Préparation onlay
        </Formation>
        <Formation heureDebut="13:00" hauteur={1} color="#5D5D5C">
          PAUSE DEJEUNER
        </Formation>
        <Formation
          heureDebut="14:00"
          hauteur={4}
          imageBottom={Illustration18}
          color="#1D5A9E"
        >
          Chirurgie : Atelier suture - théorie - pratique
        </Formation>
      </div>

      <div className={MonStyle.Jour}>
        <div className={MonStyle.NomJour}>Jeudi</div>
        <Formation
          heureDebut="9:30"
          hauteur={3}
          //imageBottom={Illustration16}
          color="#1D5A9E"
        >
          Prothèse/ Préparation - Théorie - Pratique
        </Formation>
        <Formation heureDebut="11:00" hauteur={2} color="#1D5A9E">
          Prothèse / Empreinte Camera intra-oral
        </Formation>
        <Formation heureDebut="13:00" hauteur={1} color="#5D5D5C">
          PAUSE DEJEUNER
        </Formation>
        <Formation heureDebut="14:00" hauteur={3} color="#1D5A9E">
          Implantologie Initiation à la chirurgie implantaire et à la prothèse
          sur implant
        </Formation>
        <Formation heureDebut="17:00" hauteur={1} color="#677A09">
          Plan de formation proposé par SANTEA{" "}
        </Formation>
      </div>

      <div className={MonStyle.Jour}>
        <div className={MonStyle.NomJour}>Vendredi</div>
        <Formation heureDebut="9:30" hauteur={1} color="#677A09">
          Prise en main du poste Optimisation
        </Formation>
        <Formation
          heureDebut="10:00"
          hauteur={4}
          imageTop={Illustration17}
          color="#29767D"
        >
          Étude de cas : Plan de traitement Logiciel de facturation PARTIE 1
        </Formation>

        <Formation heureDebut="13:00" hauteur={1} color="#5D5D5C">
          PAUSE DEJEUNER
        </Formation>
        <Formation heureDebut="14:00" hauteur={2} color="#29767D">
          Étude de cas : Plan de traitement Logiciel de facturation PARTIE 2
        </Formation>
        <Formation heureDebut="15:30" hauteur={1} color="#29767D">
          Optimisation : plan de traitement et nomenclature
        </Formation>
        <Formation heureDebut="17:00" hauteur={1} color="#677A09">
          Conclusion Retour sur la semaine de formation
        </Formation>
      </div>
    </div>
  );
}
