import React from "react";
import BoutonActionPrincipale from "../Design/Boutons/BoutonsActionPrincipale";
import MonStyle from "./Page404.module.css";

export default function Page404(params) {
  return (
    <div className={MonStyle.Bloc404}>
      <div className="TitreBleu">Page non trouvée</div>
      <p className="SousTitre">La page que vous recherchez n'existe plus</p>
      <BoutonActionPrincipale style={{ margin: "40px 0px" }} link="/">
        Retourner à la page d'accueil
      </BoutonActionPrincipale>
    </div>
  );
}
