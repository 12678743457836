import React from "react";
import MonStyle from "./TrainBus.module.css";

export default function TrainBus({ objet, style }) {
  return (
    <div
      className={MonStyle.TrainBus}
      style={{
        style,
      }}
    >
      {objet.image && (
        <div className={MonStyle.ContainerImage}>
          <img
            src={process.env.PUBLIC_URL + "/img/" + objet.image}
            alt="illustration ligne"
            style={{ marginRight: "10px" }}
          />
        </div>
      )}
      <div style={{ color: "black" }}>
        <span style={{ color: "#A1BB1A", marginRight: "5px" }}>•</span>
        {objet.texte}
      </div>
    </div>
  );
}
