import React from "react";
import MonStyle from "./PageFormations.module.css";
import Illustration10 from "../Images/Illustration10.jpg";
import BoutonContact from "../Design/Boutons/BoutonContact";
import PlanningFormationsCouleur from "../Components/PlanningFormationsCouleur";

export default function PageFormations(params) {
  return (
    <div>
      {/*BLOC 1*/}
      <div className={MonStyle.Bloc1}>
        <img
          src={Illustration10}
          alt="Equipe formation"
          className={MonStyle.ImageBloc1}
        />
        <div className={MonStyle.TexteBloc1}>
          <div className="TitreTurquoise">
            La formation est importante pour nous
          </div>
          <p>
            Afin de garantir une expérience patient et collaborateur optimale,
            SANTEA investit de façon significative auprès de l’ensemble de ses
            collaborateurs en terme de formation :
          </p>
          <p>A l’embauche des nouveaux collaborateurs :</p>
          <ul>
            <li>
              les secretaires , assistantes, coordinateurs ou responsables de
              centre sont formés sur les outils logiciels, les process en
              vigueur dans l’entreprise
            </li>
            <li>
              Des programmes spécifiques de formation pour les nouveaux
              praticiens ont été mis en place, en lien avec un partenariat avec
              le service odontologie de l’Hopital de la Pitie Salpetrière,
              visant à apporter tout le savoir faire sur les différentes
              facettes de la pratique odontologique. Ces sessions permettent
              aussi des travaux pratiques dans notre centre de formation situé
              en Ile de France.
            </li>
          </ul>
          <p>En formation continue</p>
          <ul>
            <li>
              Une formatrice intervient régulièrement en centre pour former et
              compléter la formation sur les outils logiciels du centre
              (Desmos,Doctolib, Muneris,…) pour le personnel administratif
            </li>
            <li>
              Des sessions régulières de formation internes sont organisées pour
              les praticiens, avec nos différents partenaires
            </li>
          </ul>
        </div>
      </div>

      {/*BLOC FORMATIONS*/}
      <div
        className={MonStyle.BlocFormations}
        style={{ backgroundColor: "white" }}
        id="formations"
      >
        <div
          className="TitreTurquoise"
          style={{ marginBottom: "40px", textAlign: "center" }}
        >
          Notre programme de formation{" "}
        </div>
        <PlanningFormationsCouleur />
      </div>

      {/*BLOC CONTACT*/}
      <div className={MonStyle.BlocContact}>
        <p className="Titre2">Contactez nous</p>
        <BoutonContact style={{ margin: "20px 0px" }} />
      </div>
    </div>
  );
}
