import React from "react";
import Entete from "../Components/Entete";
import Menu from "../Components/Menu";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageAccueil from "../Pages/PageAccueil";
import Page404 from "../Pages/Page404";
import PageServices from "../Pages/PageServices";
import PageCentres from "../Pages/PageCentres";
import PageProfessionnels from "../Pages/PageProfessionnels";
import PageValeurs from "../Pages/PageValeurs";
import PiedPage from "../Components/PiedPage";
import PageDetailCentre from "../Pages/PageDetailCentre";
import PageMentionsLegales from "../Pages/PageMentionsLegales";

export default function RoutesPubliques() {
  return (
    <div style={{ position: "relative" }}>
      <Router>
        <Entete />
        <Menu />
        <Switch>
          <Route path="/" exact component={PageAccueil} />
          <Route path="/services" component={PageServices} />
          <Route path="/centres" exact component={PageCentres} />
          <Route path="/centres/:id" component={PageDetailCentre} />
          <Route path="/professionnels" component={PageProfessionnels} />
          <Route path="/professionnels/valeurs" component={PageValeurs} />
          <Route path="/mentionslegales" component={PageMentionsLegales} />
          <Route path="*" component={Page404} />
        </Switch>
        <PiedPage />
      </Router>
    </div>
  );
}
