import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";

export default function BoutonActionPrincipale({
  couleur,
  style,
  link,
  ...props
}) {
  let theme = {};

  switch (couleur) {
    case "bleu1":
      theme = {
        backgroundColor: "#00579920",
        borderColor: "white",
        border: "2px solid",
        color: "#005799",
      };
      break;
    case "vert1":
      theme = {
        backgroundColor: "#A2BB03",
        borderColor: "white",
        border: "2px solid",
        color: "white",
      };
      break;
    case "vert2":
      theme = {
        backgroundColor: "",
        borderColor: "#8BB621",
        border: "2px solid",
        color: "#8BB621",
      };
      break;
    case "turquoise":
      theme = {
        backgroundColor: "#2DB7BC40",
        backgroundColorHover: "",

        borderColor: "white",

        border: "2px solid",
        color: "#2DB7BC",
      };
      break;
    case "turquoise2":
      theme = {
        backgroundColor: "#2DB7BC",
        backgroundColorHover: "#2DB7BC90",

        borderColor: "#2DB7BC",
        border: "2px solid",
        color: "white",
      };
      break;
    case "blanc":
      theme = {
        backgroundColor: "white",
        backgroundColorHover: "#FFFFFF90",
        borderColor: "white",
        border: "2px solid",
        color: "#005799",
      };
      break;

    default:
      theme = {
        backgroundColor: "",
        borderColor: "#005799",
        border: "2px solid",
        color: "#005799",
      };

      break;
  }

  const ThemeButton = withStyles({
    root: {
      boxShadow: "none",
      textTransform: "none",
      fontSize: 20,
      padding: "6px 20px",
      border: theme.border,
      borderRadius: "40px",
      lineHeight: 1.5,
      backgroundColor: theme.backgroundColor,
      borderColor: theme.borderColor,
      color: theme.color,
      fontWeight: 700,
      textAlign: "center",
      fontFamily: ["Lato"].join(","),
      "&:hover": {
        //backgroundColor: theme.backgroundColorHover,
        //        borderColor: "red",
        boxShadow: "none",
        backgroundColor: theme.backgroundColorHover,
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: theme.color + "20",

        //borderColor: "#005cbf",
      },
      "&:focus": {
        // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
  })(Button);

  return (
    <ThemeButton
      style={style}
      component={link ? Link : Button}
      to={link}
      {...props}
    >
      {props.children}
    </ThemeButton>
  );
}
