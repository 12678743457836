import React, { useState } from "react";
import MonStyle from "./Service.module.css";
import Illustration4 from "../Images/Illustration4.jpg";

export default function Service({ titre, description, image, ...props }) {
  //NES gestion du state
  const [afficher, setAfficher] = useState(false);

  return (
    <div className={MonStyle.Service}>
      <img
        src={image ? image : Illustration4}
        alt={titre}
        style={{ width: "100%", borderRadius: "8px 8px 0px 0px" }}
      />
      <div style={{ padding: "0px  15px 20px" }}>
        <div className="Titre2" style={{ color: "black" }}>
          {titre}
        </div>
        <div
          style={{
            width: "300px",
            textAlign: "justify",
            display: "-webkit-box",
            maxWidth: "100%",
            WebkitLineClamp: afficher ? "" : 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.children}
        </div>

        {!afficher && (
          <div
            onClick={() => setAfficher(!afficher)}
            className={MonStyle.BoutonAfficher}
          >
            En savoir plus
          </div>
        )}
      </div>
    </div>
  );
}
