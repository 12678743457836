import React from "react";
import Santea from "../Images/Santea.svg";

export default function LogoCentre({ nom, style }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        ...style,
      }}
    >
      <img
        src={Santea}
        alt="Logo Santéa"
        style={{ marginRight: "15px", height: "50px", marginTop: "5px" }}
      />
      <div>
        <div
          style={{ fontSize: "30px", letterSpacing: "1px", fontWeight: "300" }}
        >
          Santé<span style={{ color: "#879A22" }}>a</span>
        </div>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "300",
            letterSpacing: "1px",
            paddingBottom: "5px",
            marginTop: "-4px",
          }}
        >
          {nom}
        </div>
        <div
          style={{
            fontSize: "9px",
            fontWeight: "500",
            letterSpacing: "4px",
            color: "#666",
            borderTop: "1px solid #66666680",
            textTransform: "uppercase",
            padding: "5px 0px",
          }}
        >
          Centre dentaire
        </div>
      </div>
    </div>
  );
}
