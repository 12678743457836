import React, { useState, useEffect } from "react";
import MonStyle from "./MenuProfessionnel.module.css";

import Illustration2 from "../Images/Illustration2.jpg";
import Illustration12 from "../Images/Illustration12.jpg";
import Illustration13 from "../Images/Illustration13.jpg";
import Illustration14 from "../Images/Illustration14.jpg";

import { Link } from "react-router-dom";

function ItemProfessionnel({ texte, image, link, selected, onClick }) {
  return (
    <Link
      className={MonStyle.ItemProfessionnel}
      to={"/" + link}
      onClick={onClick}
      style={{
        opacity: selected ? 1 : 0.8,
        boxShadow: selected ? " 2px 4px 12px 0 rgba(0, 0, 0, 0.2)" : "",
        border: selected ? "4px solid #8db720" : "4px solid white",
        boxSizing: "border-box",
      }}
    >
      <div className={MonStyle.ContainerImage}>
        <img
          src={image ? image : Illustration2}
          className={MonStyle.Image}
          alt="menu illustration"
        />
      </div>
      <div
        className={MonStyle.TexteItem}
        style={{ backgroundColor: selected ? "#8db720" : "" }}
      >
        {texte}
      </div>
    </Link>
  );
}

export default function MenuProfessionnel() {
  //NES gestion de l'url
  let urlPage = window.location.pathname;

  //NES Gestion du state
  const [value, setValue] = useState(0);

  //USEEFFECT sur l'URL
  useEffect(() => {
    switch (urlPage) {
      case "/professionnels/valeurs":
        setValue(0);

        break;
      case "/professionnels/formations":
        setValue(1);
        break;
      case "/professionnels/accompagnement":
        setValue(2);
        break;
      case "/professionnels/travail":
        setValue(3);
        break;
      default:
        setValue(0);
        break;
    }
  }, [urlPage]);

  return (
    <div className={MonStyle.MenuProfessionnel}>
      <ItemProfessionnel
        texte="Nos valeurs"
        link="professionnels/valeurs"
        image={Illustration2}
        selected={value === 0 ? true : false}
        onClick={() => setValue(0)}
      />
      <ItemProfessionnel
        texte="Formations"
        link="professionnels/formations"
        image={Illustration12}
        selected={value === 1 ? true : false}
        onClick={() => setValue(1)}
      />
      <ItemProfessionnel
        texte="Accompagnement"
        link="professionnels/accompagnement"
        image={Illustration13}
        selected={value === 2 ? true : false}
        onClick={() => setValue(2)}
      />
      <ItemProfessionnel
        texte="Vie au travail"
        link="professionnels/travail"
        image={Illustration14}
        selected={value === 3 ? true : false}
        onClick={() => setValue(3)}
      />
    </div>
  );
}
