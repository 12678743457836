import React from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";

const MyInput = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderColor: "#EFEFEF",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EFEFEF",
      },

      "&:hover fieldset": {
        borderColor: "#EFEFEF",
        boxShadow: "2px 4px 12px 0 rgba(0, 0, 0, 0.2)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#005799",
      },
    },
  },
}))(TextField);

export default function InputRecherche({ value, onChange }) {
  //NES Gestion du state
  const [texte, setTexte] = useState("");

  const handleChange = (event) => {
    let valeur = event.target.value;
    setTexte(valeur);
    if (onChange) {
      onChange(valeur);
    }
  };

  const handleClear = () => {
    setTexte("");
    if (onChange) {
      onChange("");
    }
  };

  return (
    <MyInput
      value={texte}
      onChange={handleChange}
      fullWidth
      id="recherche"
      variant="outlined"
      placeholder="Rechercher"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            {texte && (
              <IconButton aria-label="delete" onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
