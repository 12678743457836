import React from "react";
import MenuProfessionnel from "../Components/MenuProfessionnel";
import PageValeurs from "./PageValeurs";
import { Route, Switch } from "react-router-dom";
import PageFormations from "./PageFormations";
import PageAccompagnement from "./PageAccompagnement";
import PageTravail from "./PageTravail";
import Page404 from "./Page404";

export default function PageProfessionnnels({ match }) {
  return (
    <div>
      <MenuProfessionnel match={match} />
      <Switch>
        <Route path="/professionnels/valeurs" component={PageValeurs} />
        <Route path="/professionnels/formations" component={PageFormations} />
        <Route
          path="/professionnels/accompagnement"
          component={PageAccompagnement}
        />
        <Route path="/professionnels/travail" component={PageTravail} />

        <Route path="*" component={Page404} />
      </Switch>
    </div>
  );
}
