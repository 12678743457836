import React from "react";
import MonStyle from "./PageServices.module.css";
import Illustration4 from "../Images/Illustration4.jpg";
import Illustration5 from "../Images/Illustration5.jpg";
import Illustration6 from "../Images/Illustration6.jpg";
import Illustration7 from "../Images/Illustration7.jpg";
import Illustration8 from "../Images/Illustration8.jpg";
import Illustration9 from "../Images/Illustration9.jpg";
import Illustration10 from "../Images/Illustration10.jpg";
import Service from "../Components/Service";
import BoutonActionPrincipale from "../Design/Boutons/BoutonsActionPrincipale";

export default function PageServices(params) {
  return (
    <div>
      {/* BLOC 1*/}
      <div className={MonStyle.Bloc1}>
        <div className={MonStyle.TexteEtBoutonBloc1}>
          <div className="TitreBleu">Santéa pratique le tiers payant</div>
          <div>
            <p>
              <b>Aucune avance de frais</b> pour les soins dentaires dans nos
              centres.
            </p>
            <p>
              Avec Santéa, vous profitez d’avantages qui simplifient la prise en
              charge de vos soins :
            </p>
            <ul>
              <li>
                Centres conventionnés secteur 1 : sécurité sociale, mutuelles,
                CMU, AME
              </li>
              <li>
                Devis détaillé automatisé, disponible après la première
                consultation.
              </li>
              <li>
                Santéa vous propose un paiement en 3 ou 4 fois sans frais pour
                les soins de prothèses ou d’implants.
              </li>
              <li>
                Tiers payant applicable : possibilité de dispense d’avance de
                frais, en fonction de votre mutuelle.
              </li>
            </ul>

            <p style={{ fontSize: "12px" }}>*Sous réserve d’acceptation</p>
          </div>
          <div className={MonStyle.BoutonBloc1}>
            <BoutonActionPrincipale
              style={{ margin: "40px" }}
              link="/centres#top"
            >
              Trouver un centre
            </BoutonActionPrincipale>
          </div>
        </div>
        <img
          src={Illustration10}
          alt="Equipe dentaire"
          className={MonStyle.ImageBloc1}
        />
      </div>

      {/* BLOC 2*/}
      <div className={MonStyle.Bloc2} id="soins">
        <div className="TitreBleu">
          Les soins dentaires Santéa en toute simplicité
        </div>
        <div className={MonStyle.TexteBloc2}>
          <p>
            Nos dentistes prennent en charge les consultations et urgences
            dentaires et dispensent les soins d’odontologie conservatrice, soins
            chirurgicaux avec un contrôle de post-intervention, le blanchiment
            dentaire, le détartrage, les traitements parodontaux, la taille et
            l’empreinte dentaire, la pose de prothèses dentaires, les examens de
            radio panoramique, Cone Beam et Scanner 3 D….
          </p>
          <p>
            Nos orthodontistes reçoivent les enfants et les adultes et assurent
            la pose des bagues, le débaguage, la contention, les consultations
            de suivi, la pose d’appareil orthodontique amovible, la pose
            d’appareil de prévention chez les enfants, etc.
          </p>
          <p>
            Nos implantologues interviennent lors de la pose des implants et
            pose de prothèse sur implants, du comblement osseux, du sinus lift,
            du contrôle et suivi post-opératoire…
          </p>
          <p>
            Nos praticiens assurent également des soins prothétiques :
            adjonction de dents, réparation de prothèse mobile, pose d’élément
            provisoire, de prothèse, couronne…
          </p>
        </div>
      </div>

      {/* BLOC 3*/}
      <div className={MonStyle.Bloc3}>
        <div className={MonStyle.BlocServices}>
          <Service image={Illustration4} titre="Soins dentaires">
            Chez Santea, la{" "}
            <mark>priorité est donnée aux soins conservateurs </mark>
            (traitement des caries, détartrages, plombage ou, composites, etc)
            <mark> et à la prévention</mark> des maladies des dents et des
            gencives.
          </Service>
          <Service image={Illustration5} titre="Prothèses dentaires">
            Lorsque les <mark>soins conservateurs ne sont plus possibles</mark>,
            il est <mark>nécessaire de remplacer des dents</mark> manquantes ou
            défectueuse. Il doit être envisagé la pose d’une{" "}
            <mark>prothèse dentaire </mark>
            (inlays, onlays, couronnes, bridges, prothèses partielles ou
            totales, fixes ou amovibles). En plus du{" "}
            <mark>caractère esthétique</mark>, une prothèse dentaire peut
            permettre{" "}
            <mark>d’éviter des douleurs articulaires et musculaires.</mark>
          </Service>
          <Service image={Illustration6} titre="Orthodontie">
            Cette <mark>spécialité dentaire </mark>permet avec un grand choix de
            traitement de <mark>retrouver un alignement dentaire </mark>et ainsi
            <mark> corriger des gènes au quotidien </mark>: mauvaise mastication
            (et donc problème de digestion) ; difficulté d’élocution ou
            d’articulation ; problème de respiration (apnée du sommeil).
          </Service>
          <Service image={Illustration7} titre="Implant dentaire">
            Il permet de recréer de <mark>manière durable </mark>une dent (ou
            plusieurs) manquante. La <mark>pose d’un implant </mark>est réalisée
            sous <mark>anesthésie locale.</mark> Il est inséré dans l’os de la
            mâchoire et <mark>permet de jouer le rôle de racine </mark>
            artificielle pour permettre la fixation d’une couronne (ou d’une
            prothèse). Cette solution est généralement préconisée lorsqu’une ou
            plusieurs dents ont été extraites ou sont manquantes et que l’on
            <mark> souhaite garder intacts les dents à côté.</mark>
          </Service>
          <Service image={Illustration9} titre="Esthétique dentaire">
            Permet d’améliorer <mark>l'apparence des dents</mark> et du{" "}
            <mark>sourire du patient. </mark>
            Ce <mark>rehaussement de l’image de soi </mark> par l’obtention d’un
            sourire harmonieux et dans un but purement esthétique. A condition
            que la bouche soit saine, chacun peut recourir à des traitements
            pour obtenir le sourire parfait : le{" "}
            <mark> blanchiment dentaire,</mark> les{" "}
            <mark>facettes dentaires </mark>ou encore les{" "}
            <mark>injections d'acide hyaluronique.</mark>
          </Service>
          <Service
            image={Illustration8}
            titre="Chirurgie orale (endodontie - greffes osseuses)"
          >
            <mark>L’endodontie est une spécialité</mark> de chirurgie dentaire
            qui concerne le
            <mark> traitement des racines </mark>des dents. Elle{" "}
            <mark>permet de soigner de l’intérieur</mark> une dent malade ou
            infectée afin de <mark>préserver les dents naturelles </mark>et
            d’éviter ainsi la pose d’implants et de prothèses.
            <br /> <br />
            <b>Greffes osseuses :</b> Dans certaines situation,{" "}
            <mark>l’os de la mâchoire est insuffisant</mark> pour accueillir un
            implant. Il sera donc <mark>parfois nécessaire </mark>de procéder à
            une reconstruction osseuse en hauteur et/ou en épaisseur en
            effectuant des greffes osseuses : <mark>soit avant </mark>la pose de
            l’implant (greffe pré-implantaire) <mark> soit pendant </mark>la
            pose de l’implant (greffe per-implantaire)
          </Service>
        </div>

        <BoutonActionPrincipale
          style={{ margin: "80px 0px" }}
          link="/centres#top"
        >
          Trouver un centre
        </BoutonActionPrincipale>
      </div>
    </div>
  );
}
