import React, { useState, useEffect } from "react";
import MonStyle from "./PageDetailCentre.module.css";
import LogoCentre from "../Components/LogoCentre";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { getCentre } from "../Data/DataCentres";
import TrainBus from "../Components/TrainBus";
import GoogleMapReact from "google-map-react";
import GaleriePhotos from "../Components/GaleriePhotos";
import BoutonDoctolib from "../Design/Boutons/BoutonDoctolib";

export default function PageDetailCentre({ match }) {
  const Localisation = ({ text }) => (
    <div style={{ color: "red" }}>
      <LocationOnIcon style={{ fontSize: "40px" }} />
    </div>
  );

  //NES Gestion du state
  const [data, setData] = useState({});

  //USEEFFECT
  useEffect(() => {
    if (match.params.id) {
      getCentre({ id: match.params.id }).then((data) => {
        setData(data);
      });
    }
  }, [match.params.id]);

  return (
    <div>
      <div className={MonStyle.BlocCentreEtMap}>
        {/* BLOC INFORMATIONS CENTRE*/}
        <div className={MonStyle.BlocInformationCentre}>
          <LogoCentre nom={data.nom} style={{ marginBottom: "40px" }} />
          {/* ADRESSE*/}
          <div className={MonStyle.Adresse}>
            <div>{data.adresse}</div>
            <div>{data.code_postal + " " + data.ville}</div>
          </div>
          {/* HORAIRES*/}
          <div className={MonStyle.Horaires}>
            <div>Horaires d'ouverture (6/7j) :</div>
            <div>
              Lundi - vendredi :{" "}
              <span className={MonStyle.DataVert}>{data.horaires_semaine}</span>
            </div>
            <div>
              Samedi :
              <span className={MonStyle.DataVert}>{data.horaires_samedi}</span>{" "}
            </div>
          </div>
          {/* CONTACT*/}
          <div className={MonStyle.Contact}>
            <div>
              Tél : <span className={MonStyle.DataVert}>{data.telephone}</span>{" "}
            </div>
            <div>
              mail : <span className={MonStyle.DataVert}>{data.mail}</span>
            </div>
          </div>
          {/* ACCES*/}
          <div className={MonStyle.Acces}>
            <div className={MonStyle.BlocTitreAcces}>
              <div className={MonStyle.SeparateurTitreAcces}></div>
              <div className={MonStyle.TitreAcces}>
                <LocationOnIcon style={{ marginRight: "10px" }} />
                Accès
              </div>
              <div className={MonStyle.SeparateurTitreAcces}></div>
            </div>
            {/* BLOC TRAIN */}

            {data?.trains?.length >= 1 && (
              <div>
                <div className="Titre2Vert">Train :</div>
                {data?.trains?.map((train) => (
                  <TrainBus
                    objet={train}
                    style={{ margin: "10px" }}
                    key={train.texte}
                  />
                ))}
              </div>
            )}
            {/* BLOC BUS */}
            {data?.bus?.length >= 1 && (
              <div>
                <div className="Titre2Vert">Bus :</div>
                {data?.bus?.map((bus, index) => (
                  <TrainBus
                    objet={bus}
                    style={{ margin: "10px" }}
                    key={index}
                  />
                ))}
              </div>
            )}
            {/* BLOC PARKING */}
            {data?.parkings?.length >= 1 && (
              <div>
                <div className="Titre2Vert">Parkings :</div>
                {data?.parkings?.map((parking, index) => (
                  <TrainBus
                    objet={parking}
                    style={{ margin: "10px" }}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <BoutonDoctolib url={data.url_doctolib} />
        </div>
        {/* MAP*/}
        <div className={MonStyle.Carte}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBoG7SWFD1DxHGoXyH3jHxATEkxyaLPY8Y",
            }}
            defaultCenter={{
              lat: data.latitude,
              lng: data.longitude,
            }}
            defaultZoom={18}
          >
            <Localisation />
          </GoogleMapReact>
        </div>
      </div>

      {/* PHOTOS */}
      <div className={MonStyle.BlocPhotos}>
        <GaleriePhotos photos={data.photos} />
      </div>
    </div>
  );
}
