import React from "react";
import BoutonActionPrincipale from "./BoutonsActionPrincipale";

export default function BoutonRendezVous({ couleur, style }) {
  return (
    <div>
      <BoutonActionPrincipale
        couleur={couleur}
        style={style}
        link="/centres#top"
      >
        Prendre un rendez-vous
      </BoutonActionPrincipale>
    </div>
  );
}
