import React from "react";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  imageList: {
    width: "100vw",
  },
}));

export default function GaleriePhotos({ photos, style }) {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style}>
      <ImageList
        rowHeight={250}
        className={classes.imageList} //cols={3}
        cols={window.innerWidth > 400 ? 5 : 1}
      >
        {photos?.map((item) => (
          <ImageListItem
            key={item.src}
            cols={window.innerWidth > 400 ? item.cols : 1}
            style={{ backgroundColor: "white", margin: "0px" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/" + item.src}
              alt={item.src}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
