import React from "react";
import MonStyle from "./Centre.module.css";
import BoutonActionPrincipale from "../Design/Boutons/BoutonsActionPrincipale";
import LogoCentre from "./LogoCentre";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CallIcon from "@material-ui/icons/Call";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BoutonDoctolib from "../Design/Boutons/BoutonDoctolib";

export default function Centre({ centre }) {
  return (
    <div className={MonStyle.CentreEtActualite}>
      <div className={MonStyle.Centre}>
        {/*LOGO */}
        <div className={MonStyle.Logo}>
          <LogoCentre nom={centre.nom} style={{ marginBottom: "20px" }} />
        </div>
        <div className={MonStyle.Info}>
          {/*ADRESSE */}
          <div className={MonStyle.Bloc}>
            <LocationOnIcon className={MonStyle.Icone} />
            <div>
              <div>{centre.adresse}</div>
              <div>{centre.code_postal + " " + centre.ville}</div>
              <div className={MonStyle.Region}>{centre.region}</div>
            </div>
          </div>
          {/* TELEPHONE*/}
          <div className={MonStyle.Bloc}>
            <CallIcon className={MonStyle.Icone} />
            <div>{centre.telephone}</div>
          </div>
          {/* HORAIRES*/}
          <div className={MonStyle.Bloc}>
            <AccessTimeIcon className={MonStyle.Icone} />
            <div>
              <div className={MonStyle.Label}>
                Lun. - ven. {centre.horaires_semaine}
              </div>
              <div className={MonStyle.Label}>
                Samedi {centre.horaires_samedi}
              </div>
            </div>
          </div>
        </div>
        {centre.date_ouverture ? (
          <div className={MonStyle.Boutons}>
            <div className={MonStyle.BlocOuverture}>
              <div className="SousTitre">Ouverture à venir</div>
              <div className="Titre2Vert">{centre.date_ouverture}</div>
            </div>
          </div>
        ) : (
          <div className={MonStyle.Boutons}>
            <BoutonDoctolib
              style={{ margin: "10px 0px " }}
              url={centre.url_doctolib}
              disabled={centre.url_doctolib ? false : true}
            />
            <BoutonActionPrincipale
              couleur="vert2"
              link={"/centres/" + centre.id + "#top"}
              style={{ margin: "10px 0px " }}
              fullWidth
            >
              plus d'informations
            </BoutonActionPrincipale>
          </div>
        )}
      </div>
      {centre?.actualites && (
        <div className={MonStyle.Actualite}>{centre?.actualites}</div>
      )}
    </div>
  );
}
