import React from "react";
import MonStyle from "./Entete.module.css";
import Logo from "../Images/Logo.svg";
import MenuMobile from "./MenuMobile";
import { Link } from "react-router-dom";
import BoutonDoctolib from "../Design/Boutons/BoutonDoctolib";

export default function Entete() {
  return (
    <div className={MonStyle.Entete} id="top">
      <Link to="/">
        <img src={Logo} className={MonStyle.Logo} alt="Logo Santea" id="Logo" />
      </Link>

      <div className={MonStyle.MenuMobile}>
        <MenuMobile />
      </div>
      <div className={MonStyle.BoutonRdv}>
        <BoutonDoctolib />
      </div>
    </div>
  );
}
