import React from "react";
import BoutonActionPrincipale from "./BoutonsActionPrincipale";
import PhoneIcon from "@material-ui/icons/Phone";
export default function BoutonTelephoner({ numero, couleur, style }) {
  return (
    <div>
      <BoutonActionPrincipale
        couleur={couleur}
        style={style}
        startIcon={<PhoneIcon />}
        href={"tel:" + numero}
      >
        {numero}
      </BoutonActionPrincipale>
    </div>
  );
}
