import React from "react";
import MonStyle from "./Partenaires.module.css";
import LogoZimmer from "../Images/LogoZimmer.png";
import LogoBiobank from "../Images/LogoBiobank.png";
import LogoZimmer2 from "../Images/LogoZimmer2.png";
import LogoNobel from "../Images/LogoNobel.png";
import LogoIdi from "../Images/LogoIdi.png";

export default function Partenaires({ style }) {
  return (
    <div className={MonStyle.Partenaires} style={style}>
      <a
        href="https://www.zimmer-biomet.fr/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={LogoZimmer} alt="Zimmer" className={MonStyle.Logo} />
      </a>
      <a
        href="https://www.biobank.fr/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={LogoBiobank} alt="BioBank" className={MonStyle.Logo} />
      </a>
      <a
        href="https://www.zimmerbiometdental.com/en"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={LogoZimmer2} alt="Zimmer" className={MonStyle.Logo} />
      </a>
      <a
        href="https://www.nobelbiocare.com/fr-fr"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={LogoNobel} alt="Nobel" className={MonStyle.Logo} />
      </a>
      <a
        href="https://www.idi-dental.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={LogoIdi} alt="Idi" className={MonStyle.Logo} />
      </a>
    </div>
  );
}
