import CentreChampigny from "../Images/CentreChampigny.jpg";
import CentreLilles from "../Images/CentreLilles.jpeg";
import CentreSaintMaur from "../Images/CentreSaintMaur.jpg";
import CentreFlandres from "../Images/CentreFlandres.jpg";
import CentreMontreuil from "../Images/CentreMontreuil.jpeg";

const DataCentres = [
  {
    id: "champigny",
    image: CentreChampigny,
    nom: "Champigny",
    adresse: "Tranféré à St-Maur",
    code_postal: "",
    ville: "",
    region: "",
    latitude: 48.86,
    longitude: 2.28,
    horaires_semaine: "",
    horaires_samedi: "",
    telephone: "+33 (0)1 53 99 13 98",
    mail: "contact@santea-champigny.com",
    trains: [{ image: "RERA.png", texte: "Arrêt : Joinville-le-pont" }],
    bus: [
      {
        image: "Bus_106-108-110-317.png",
        texte: "Arrêt : La Fourchette de Champigny",
      },
    ],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    //url_doctolib:"https://www.doctolib.fr/centre-dentaire/champigny-sur-marne/santea-champigny-sur-marne",
    url_doctolib:
      "https://www.doctolib.fr/centre-dentaire/saint-maur-des-fosses/santea-saint-maur-des-fosses",
    actualites:
      "Le centre a été transféré au centre de St-Maur, 60 avenue Diderot 94100 Saint-Maur-des-Fossés - Tél: 01 53 99 13 98",
  },
  {
    id: "lille",
    image: CentreLilles,
    nom: "Lille",
    adresse: "24, rue du Molinel",
    code_postal: "59000",
    ville: "Lille",
    region: "Nord",
    latitude: 50.6352123,
    longitude: 3.0694559,
    horaires_semaine: "8h00 - 20h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)3 20 04 16 16",
    mail: "contact@santea-lille.com",
    trains: [{ texte: "5min à pied de la Gare Lille Flandres" }],
    bus: [
      {
        image: "Bus_12-14-Lille.gif",
        texte: "Arrêt : Gare Rue de Tournai",
      },
      {
        image: "Bus_12-14-Lille.gif",
        texte: "Arrêt : Tanneurs",
      },
    ],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib: "https://www.doctolib.fr/centre-dentaire/lille/santea-lille",
  },
  {
    id: "beauvais",
    image: "",
    nom: "Beauvais",
    adresse: "20 Avenue Descartes",
    code_postal: "60000",
    ville: "Beauvais",
    region: "OISE",
    latitude: 49.408398,
    longitude: 2.1167982,
    horaires_semaine: "8h30 - 19h00",
    horaires_samedi: "8h30 - 18h00",
    telephone: "+33 (0)3 75 15 03 90",
    mail: "contact@santea-beauvais.com",
    trains: [],
    bus: [],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib:
      "https://www.doctolib.fr/centre-dentaire/beauvais/santea-beauvais",
  },
  {
    id: "saint-maur",
    image: CentreSaintMaur,
    nom: "Saint-Maur",
    adresse: "60 avenue Diderot",
    code_postal: "94100",
    ville: "Saint-Maur-des-Fossés",
    region: "Val-de-marne",
    latitude: 48.8024582,
    longitude: 2.4852764,
    horaires_semaine: "8h00 - 20h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)1 53 99 13 98",
    mail: "contact@santea-saintmaur.com",
    trains: [{ image: "RERA.png", texte: "Arrêt : Le Parc de Saint-Maur" }],
    bus: [
      {
        image: "Bus_317.png",
        texte: "Arrêt : Mairie de Saint-Maur",
      },
      {
        image: "Bus_107-112-306.png",
        texte: "Arrêt : Saint-Maur - Créteil RER",
      },
    ],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib:
      "https://www.doctolib.fr/centre-dentaire/saint-maur-des-fosses/santea-saint-maur-des-fosses",
  },
  {
    id: "paris-flandre",
    image: CentreFlandres,
    nom: "Paris - Flandre",
    adresse: "145, avenue de Flandre",
    code_postal: "75019",
    ville: "Paris",
    region: "Paris",
    latitude: 48.893121,
    longitude: 2.379466,
    horaires_semaine: "8h00 - 20h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)1 40 35 35 83",
    mail: "contact@santea-paris-flandre.com",
    trains: [
      { image: "RERE.png", texte: "Arrêt : Rosa Parks" },
      { image: "Metro7.png", texte: "Arrêt : Corentin Cariou / Crimée" },
    ],
    bus: [
      {
        image: "Bus-54.jpeg",
        texte: "Arrêt : Flandre",
      },
      {
        image: "Bus-60.jpeg",
        texte: "Arrêt : Flandre",
      },
    ],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib: "https://www.doctolib.fr/centre-dentaire/paris/santea-paris",
  },
  {
    id: "blois",
    image: CentreFlandres,
    nom: "Blois",
    adresse: "7, rue des corderies",
    code_postal: "41000",
    ville: "Blois",
    region: "Loir-et-Cher",
    latitude: 47.5829088,
    longitude: 1.340949,
    horaires_semaine: "8h00 - 20h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)2 42 44 00 90",
    mail: "contact@santea-blois.com",
    trains: [],
    bus: [],
    url_doctolib: "https://www.doctolib.fr/centre-dentaire/blois/santea-blois",
  },
  {
    id: "montreuil",
    image: CentreMontreuil,
    nom: "Montreuil",
    adresse: "38, boulevard Rouget de Lisle",
    code_postal: "93100",
    ville: "Montreuil",
    region: "Seine-saint-denis",
    latitude: 48.8600772,
    longitude: 2.4384283,
    horaires_semaine: "8h00 - 20h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)1 84 74 07 65",
    mail: "contact@santea-montreuil.com",
    trains: [{ image: "metro-9.jpeg", texte: "Arrêt : Croix de Chavaux" }],
    bus: [
      {
        image: "Bus-115.jpeg",
        texte: "Arrêt : Porte Des Lilas-Metro",
      },
      {
        image: "Bus-102.jpeg",
        texte: "Arrêt : Croix de Chavaux",
      },
    ],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib:
      "https://www.doctolib.fr/centre-dentaire/montreuil/santea-montreuil",
  },
  {
    id: "avignon",
    image: "",
    nom: "Avignon",
    adresse: "4 route de Lyon",
    code_postal: "84000",
    ville: "Avignon",
    region: "Provence",
    latitude: 43.9500812,
    longitude: 4.8197859,
    horaires_semaine: "8h30 - 20h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)4 65 00 04 65",
    mail: "contact@santea-avignon.com",
    trains: [
      { image: "Train.png", texte: "Arrêt : Gare d'Avignon TGV" },
      { image: "Train.png", texte: "Arrêt : Gare d'Avignon Centre" },
    ],
    bus: [
      {
        image: "Bus_T1_C2_C3.png",
        texte: "Arrêt : Université",
      },
    ],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib:
      "https://www.doctolib.fr/centre-dentaire/avignon/santea-avignon",
  },
  {
    id: "nimes",
    image: "",
    nom: "Nimes",
    adresse: "102 allée de l'Amérique Latine",
    code_postal: "30900",
    ville: "Nîmes",
    region: "Occitanie",
    latitude: 43.820945739746094,
    longitude: 4.362607479095459,
    horaires_semaine: "9h00 - 19h00",
    horaires_samedi: "9h00 - 18h00",
    telephone: "+33 (0)4 48 27 09 30",
    mail: "contact.nimes@santea-france.com",
    trains: [{ image: "Train.png", texte: "Arrêt : Gare Nîmes centre" }],
    bus: [{ image: "T1.png", texte: "Tram : Arrêt NEMAUSA" }],
    photos: [],
    url_doctolib:
      "https://www.doctolib.fr/centre-dentaire/nimes/santea-ods-st-maur-champigny-paris-lille-blois",
    date_ouverture: "",
  },
  {
    id: "Le Havre",
    image: "",
    nom: "Le Havre",
    adresse: "",
    code_postal: "",
    ville: "",
    region: "Normandie",
    //latitude: 43.9500812,
    //longitude: 4.8197859,
    horaires_semaine: "",
    horaires_samedi: "",
    telephone: "",
    mail: "",
    trains: [],
    bus: [],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ], //url_doctolib: "",
    date_ouverture: "Fin 2022",
  },
  {
    id: "Reims",
    image: "",
    nom: "Reims",
    adresse: "8b rue Edouard Mignot",
    code_postal: "51100",
    ville: "Reims",
    region: "Grand Est",
    latitude: 49.2608633,
    longitude: 4.0234434,
    horaires_semaine: "09h00 - 19h00",
    horaires_samedi: "Fermé",
    telephone: "+33(0) 3 26 91 18 28",
    mail: "",
    trains: [
      { image: "Train.png", texte: "A 200 mètres gare SNCF Reims Centre" },
    ],
    parkings: [
      {
        image: "Parking.jpg",
        texte: "Parking gare de Reims Clairmarais – EFFIA (à 210m)",
      },
    ],
    bus: [],
    photos: [
      { src: "Santea_HD_7.jpg", cols: 2 },
      { src: "Santea_HD_12.jpg", cols: 1 },
      { src: "Santea_HD_4.jpg", cols: 2 },
      { src: "Santea_HD_3.jpg", cols: 1 },
      { src: "Santea_HD_11.jpg", cols: 1 },
      { src: "Santea_HD_5.jpg", cols: 3 },
    ],
    url_doctolib:
      "https://www.doctolib.fr/centre-medical-et-dentaire/reims/centre-medico-dentaire-clairmarais-reims",
    //date_ouverture: "Fin 2022",
  },
];

export const getCentres = async (texteRecherche) => {
  if (texteRecherche) {
    let retour = [];
    DataCentres.forEach((centre) => {
      let chaineRecherche =
        centre.nom +
        centre.ville +
        centre.code_postal +
        centre.region +
        centre.adresse;
      let regExp = new RegExp(`${texteRecherche.replace(" ", "(.*)")}`, "i");
      let index = chaineRecherche.search(regExp);

      if (index > -1) {
        retour.push(centre);
      }
    });
    return retour;
  } else {
    return DataCentres;
  }
};

export const getCentresFuturs = async () => {
  let retour = DataCentres;

  retour = DataCentres.filter((item) => item.date_ouverture !== undefined);
  return retour;
};

export const getCentre = async ({ id }) => {
  let retour = {};

  if (id) {
    retour = DataCentres.find((item) => item.id === id);
  }

  return retour;
};
